import React, { createRef, useEffect, useState } from "react";
import "./AddToHomescreen.css";
import shoppingbag from "icons/shopping_bag.svg";
import {
  CloseWindowButton,
  PrimaryButton,
} from "components/Shared/Buttons/Buttons";
import { LiveWindow } from "components/Shared/LiveWindow/LiveWindow";

interface AddToHomescreenProps {
  showAddToHomeScreen: boolean;
  closeAddToHomeScreen: () => void;
}

const AddToHomescreen = ({
  showAddToHomeScreen,
  closeAddToHomeScreen,
}: AddToHomescreenProps) => {
  const [isInstallable, setIsInstallable] = useState<boolean>(false);
  const addToHomeRef = createRef<HTMLButtonElement>();
  const closeButtonRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    if (
      addToHomeRef &&
      addToHomeRef.current &&
      showAddToHomeScreen &&
      isInstallable
    ) {
      addToHomeRef.current.focus();
    }
  }, [addToHomeRef, showAddToHomeScreen, isInstallable]);

  useEffect(() => {
    // Initialize deferredPrompt for use later to show browser install prompt.
    let deferredPrompt: any;
    const addContainer = document.querySelector<HTMLDivElement>(
      ".add-homescreen-container"
    );
    const addBtn = document.querySelector<HTMLButtonElement>(
      ".add-homescreen-button"
    );

    window.addEventListener("beforeinstallprompt", (e: any) => {
      // Handle Chrome 67 and earlier from automatically showing the prompt, so that e.prompt() won't get undefined
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA

      setIsInstallable(true);
    });

    if (addBtn && addContainer) {
      addBtn.addEventListener("click", (e: any) => {
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          deferredPrompt = null;
        });
      });

      window.addEventListener("appinstalled", () => {
        // Clear the deferredPrompt so it can be garbage collected
        deferredPrompt = null;

        // Hide the app-provided install promotion
        closeAddToHomeScreen();
      });
    }
  }, [closeAddToHomeScreen]);

  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { key } = event;
      if (key === "Escape") {
        closeAddToHomeScreen();
        return;
      }
      if (key !== "Tab") {
        return;
      }
      if (document.activeElement?.id === "add-homescreen-close") {
        event.preventDefault();
        addToHomeRef.current?.focus();
      } else if (document.activeElement?.id === "add-homescreen-button") {
        event.preventDefault();
        closeButtonRef.current?.focus();
      }
    };

    if (showAddToHomeScreen && isInstallable) {
      window.addEventListener("keydown", handleUserKeyPress);

      return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
      };
    }
  }, [
    closeButtonRef,
    addToHomeRef,
    closeAddToHomeScreen,
    showAddToHomeScreen,
    isInstallable,
  ]);

  return (
    <>
      <LiveWindow
        aria-describedby="add-homescreen-text-content"
        className={`add-homescreen-container ${
          showAddToHomeScreen && isInstallable ? "" : "add-homescreen-hide"
        }`}
      >
        <header className="add-homescreen-header">
          <CloseWindowButton
            id="add-homescreen-close"
            onClick={closeAddToHomeScreen}
            ref={closeButtonRef}
          />
        </header>
        <div className="add-homescreen-content-container">
          <img className="add-homescreen-icon" src={shoppingbag} alt="" />
          <div
            className="add-homescreen-text-container"
            id="add-homescreen-text-content"
          >
            <div className="add-homescreen-text">
              Legg handlelisten på hjem-skjermen
            </div>
            <div className="add-homescreen-subtext">
              Nå handlelisten med ett trykk!
            </div>
          </div>
        </div>
        <PrimaryButton
          id="add-homescreen-button"
          className="add-homescreen-button"
          ref={addToHomeRef}
        >
          Legg til på Hjem-skjermen
        </PrimaryButton>
      </LiveWindow>
    </>
  );
};

export default AddToHomescreen;
