export enum UserRole {
  Owner = "USER_ROLE_OWNER",
  Editor = "USER_ROLE_EDITOR",
}

export interface IShoppingList {
  userId: string;
  listName: string;
  items: IItem[];
}

export interface IItem {
  name: string;
  id: number;
  category: string;
  checked: boolean;
  quantity: number;
  note: string;
  createTime: Date;
}

export interface IItemList {
  name: string;
  items: IItem[];
}

export interface ICategory {
  name: string;
  items: IItem[];
}

export interface IFoodCategoryData {
  name: string;
  sub_category: string;
  main_category: string;
}

export interface ISearchResult {
  foodData: IProduct;
  similarity: number;
}

export interface IShoppingListsData {
  userId: string;
  lists: IList[];
}

export interface IList {
  name: string;
  id: number;
  shareId: string;
  role: string;
  private: boolean;
}

export interface IParams {
  listid: string;
  userid: string;
  shareListID: string;
}

export interface IStoreActiveList {
  listID: number;
}

export interface IExtendedList {
  user: string;
  id: number;
  name: string;
  shareId: string;
}

export enum Status {
  Loading,
  Error,
  Ok,
}

export enum Role {
  Owner = 0,
  Editor = 1,
}

export interface Settings {
  showCategorization: boolean;
}

export interface IDragPosition {
  droppableId: string;
  index: number;
}

export interface IProduct {
  name: string;
  category: string;
}

export interface ISimilarProduct {
  item: IItem;
  similarity: number;
  originalName: string;
}

export interface IProductCategoryData {
  productGlossary: IProduct[];
  categories: string[];
  checkedCategoryName: string;
}
