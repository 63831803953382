import { compareTwoStrings } from "string-similarity";

const STARTS_WITH_WEIGHT = 0.5;
const ABSOLUTE_WEIGHT = 0.1;
const DICE_WEIGHT = 1.0;
const TOTAL_WEIGHT = STARTS_WITH_WEIGHT + ABSOLUTE_WEIGHT + DICE_WEIGHT;

const startsWith = (s1: string, s2: string): number => {
  const regex = new RegExp("^" + s1 + "(.*)", "i");
  return regex.test(s2) ? 0 : 1;
};

const absoluteContainment = (s1: string, s2: string): number => {
  const regex = new RegExp("(.*)" + s1 + "(.*)", "i");
  return regex.test(s2) ? 0 : 1;
};

const diceCoefficient = (s1: string, s2: string): number => {
  return 1 - compareTwoStrings(s1, s2);
};

const removeDiacritics = (str: string): string => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
const removeSpecialCharacters = (str: string): string =>
  str.replace(/[^ÆØÅæøåa-zA-Z ]/g, "");

const simplifyString = (str: string): string =>
  removeSpecialCharacters(removeDiacritics(str));

export const calculateStringSimilarity = (s1: string, s2: string) => {
  s1 = simplifyString(s1);
  s2 = simplifyString(s2);
  const startsWithScore = startsWith(s1, s2);
  const absolute = absoluteContainment(s1, s2);
  const dice = diceCoefficient(s1, s2);

  return (
    1 -
    (startsWithScore * STARTS_WITH_WEIGHT +
      absolute * ABSOLUTE_WEIGHT +
      dice * DICE_WEIGHT) /
      TOTAL_WEIGHT
  );
};

export default calculateStringSimilarity;
