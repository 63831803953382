import React, { useContext } from "react";
import userContext from "contexts/userContext";
import { IList } from "types/types";
import { deleteShoppingList } from "client/shoppingListClient";
import PopupWindow from "components/Shared/PopupWindow/PopupWindow";
import DeleteButton from "components/Shared/DeleteButton/DeleteButton";
import "../PopupWindows.css";
import { SecondaryButton } from "components/Shared/Buttons/Buttons";

interface IDeleteListProps {
  shoppingList: IList;
  closeDeleteListPopup: () => void;
  returnToMainMenu: () => void;
}

const DeleteShoppingList = ({
  shoppingList,
  closeDeleteListPopup,
  returnToMainMenu,
}: IDeleteListProps) => {
  const { userID, deletePersonalList } = useContext(userContext);

  const handleDeleteList = () => {
    deletePersonalList(shoppingList);
    deleteShoppingList(userID, shoppingList.id);
    returnToMainMenu();
    closeDeleteListPopup();
  };

  return (
    <PopupWindow closePopup={closeDeleteListPopup}>
      <h3 className="popup-header">{`Vil du slette \u00ab${shoppingList.name}\u00bb?`}</h3>
      <div className="popup-buttons-container">
        <SecondaryButton onClick={closeDeleteListPopup}>Avbryt</SecondaryButton>
        <DeleteButton
          id="confirm-delete-list-click"
          onClick={handleDeleteList}
        />
      </div>
    </PopupWindow>
  );
};

export default DeleteShoppingList;
