import React, { useContext, useEffect, useState } from "react";
import "./CategoryList.css";
import { ICategory, IItem, IItemList } from "types/types";
import DeleteItemsButton from "../DeleteItemsButton/DeleteItemsButton";
import ShoppingListIllustration from "../ShoppingListIllustration/ShoppingListIllustration";
import Category from "./Category/Category";
import { DragDropContext } from "react-beautiful-dnd";
import { categorizeList, reorderCategoryList } from "./categoryListUtils";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import userContext from "contexts/userContext";

interface ICategoryList {
  itemList: IItemList;
  coopCategories: string[];
  checkedCategoryName: string;
  toggleChecked: (item: IItem) => void;
  deleteCheckedItems: () => void;
  deleteItem: (deleteItem: IItem) => void;
  updateItemCategory: (item: IItem, newCategory: string) => void;
}

export const CategoryList = ({
  itemList,
  coopCategories,
  checkedCategoryName,
  toggleChecked,
  deleteCheckedItems,
  deleteItem,
  updateItemCategory,
}: ICategoryList) => {
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const { activeList }: UserContextValues = useContext(userContext);

  useEffect(() => {
    const newCategoryList = categorizeList(
      itemList,
      checkedCategoryName,
      coopCategories
    );
    setCategoryList(newCategoryList);
  }, [itemList, coopCategories, checkedCategoryName]);

  if (itemList.items.length === 0) {
    return <ShoppingListIllustration />;
  }

  const anyItemsChecked = categoryList
    .flatMap((category) => category.items)
    .map((item) => item.checked)
    .includes(true);

  const items = categoryList.flatMap((category) => category.items);

  const itemsChecked = items.filter((item) => item.checked);

  const handleDragStart = () => {
    document.body.style.pointerEvents = "none";
  };

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    document.body.style.pointerEvents = "auto";
    if (categoryList) {
      setCategoryList(
        reorderCategoryList(source, destination, categoryList, activeList)
      );
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <div className="category-container-header">
        <h3 className="category-info-text" id="category-info-text">
          VARER I DIN HANDLELISTE
        </h3>
        <div className="category-item-counter">{`${itemsChecked.length} / ${items.length}`}</div>
      </div>
      <ul className="category-container" aria-labelledby="category-info-text">
        {categoryList.map((category) => (
          <Category
            checkedCategoryName={checkedCategoryName}
            key={category.name}
            category={category}
            toggleChecked={toggleChecked}
            deleteItem={deleteItem}
            updateItemCategory={updateItemCategory}
          />
        ))}
        {anyItemsChecked && (
          <DeleteItemsButton deleteCheckedItems={deleteCheckedItems} />
        )}
      </ul>
    </DragDropContext>
  );
};

export default CategoryList;
