import React from "react";
import "./ShoppingListIllustration.css";
import upArrowIcon from "icons/pil_opp.svg";
import salmonIcon from "icons/laks.svg";
import documentIcon from "icons/document.svg";
import bananasIcon from "icons/bananas.svg";

export const ShoppingListIllustration = () => {
  return (
    <div className="figure-container">
      <div className="figure-title-container">
        <img className="figure-up-arrow" src={upArrowIcon} alt="" />
        <h3 className="figure-title">Legg til varer</h3>
      </div>
      <span className="figure-text">
        Start med å legge til varer i din handleliste i søkefeltet over
      </span>
      <div className="figure-images">
        <img className="figure-salmon" src={salmonIcon} alt="" />
        <img className="figure-document" src={documentIcon} alt="" />
        <img className="figure-bananas" src={bananasIcon} alt="" />
      </div>
    </div>
  );
};

export default ShoppingListIllustration;
