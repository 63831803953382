import Heading from "components/Heading/Heading";
import Headerbutton from "components/Shared/HeaderButton/HeaderButton";
import React, { useContext, useState } from "react";
import shopping_list_icon from "icons/header/shopping_list_icon.svg";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import userContext from "contexts/userContext";
import StoreMap from "components/StoreMap/StoreMap";
import settingsIconSelected from "icons/more_horizontal_white.svg";
import settingsIcon from "icons/more_horizontal_blue.svg";
import { AnimatePresence } from "framer-motion";
import Menu from "components/Menu/Menu";
import { useNavigate } from "react-router-dom";

const MapPage = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const navigate = useNavigate();
  const { activeList }: UserContextValues = useContext(userContext);
  const moveToShoppingListPage = () => {
    navigate("/");
  };

  return (
    <>
      <Heading listName={activeList.name}>
        <Headerbutton
          id="shoppinlist-button"
          icon={shopping_list_icon}
          selectedIcon={shopping_list_icon}
          label="Liste"
          isActive={false}
          handleClick={moveToShoppingListPage}
        />
        <Headerbutton
          id="menu-button"
          icon={settingsIcon}
          selectedIcon={settingsIconSelected}
          label="Meny"
          isActive={showMenu}
          handleClick={() => setShowMenu(!showMenu)}
        />
      </Heading>
      <AnimatePresence>
        {showMenu && <Menu setShowMenu={setShowMenu} key="menu" />}
      </AnimatePresence>
      <StoreMap />
    </>
  );
};

export default MapPage;
