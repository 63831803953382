import React from "react";
import "./ErrorPage.css";
import confused_man from "icons/confused_man.svg";

const ErrorPage = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="eror-page-container">
      <div className="error-header"></div>
      <div className="error-container">
        <img
          className="error-container-icon"
          src={confused_man}
          alt=""
        />
        <h1 className="error-container-title">Obs!</h1>
        <h3 className="error-container-sub-title">
          Noe galt skjedde.
        </h3>
        <span className="error-container-text">
          Prøv å laste inn siden på nytt.
        </span>
      </div>
      <button className="error-button" onClick={() => refreshPage()}>
        Last inn på nytt
      </button>
    </div>
  );
};

export default ErrorPage;
