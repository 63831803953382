import React from "react";
import { IList } from "types/types";
import backarrow from "icons/backArrow.svg";

import del from "icons/delete.svg";
import "../ListOptionPage/ListOptionPage.css";
import "../Menu.css";

interface ISharedListOptionPage {
  sharedListChosen: IList | undefined;
  returnToMainMenu: () => void;
  showRemoveSharedListPopup: () => void;
}

const SharedListOptionPage = ({
  sharedListChosen,
  returnToMainMenu,
  showRemoveSharedListPopup,
}: ISharedListOptionPage) => {
  return (
    <>
      <div className="list-options">
        <div className="menu-element">
          <h3 className="menu-header">
            {sharedListChosen ? sharedListChosen.name : ""}
          </h3>
        </div>
        <hr className="horizontal-line" />
        <div className="list-option-element">
          <button
            className="menu-option-button"
            onClick={() => {
              returnToMainMenu();
            }}
          >
            <img className="menu-option-icon" src={backarrow} alt="" />
            <span>Tilbake</span>
          </button>
        </div>
        <hr className="horizontal-line" />
        <div id="remove-list" className="list-option-element">
          <button
            className="delete-option-button"
            onClick={showRemoveSharedListPopup}
          >
            <img className="menu-option-icon" src={del} alt="" />
            <span className="menu-element-button-delete">Fjern delt liste</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SharedListOptionPage;
