import React from "react";
import "./InfoPopup.css";
import { motion } from "framer-motion";

interface IInfoProps {
  message: string;
  buttonText: string;
  handleClick?: () => void;
}

const InfoPopup = ({ message, buttonText, handleClick }: IInfoProps) => {
  const variants = {
    initial: {
      scale: 0.9,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
    show: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };
  return (
    <motion.div
      className="info-popup-container"
      initial="initial"
      exit="initial"
      animate="show"
      variants={variants}
    >
      <div className="info-popup">
        <span className="info-popup-message" aria-live="assertive">
          {message}
        </span>
        <button
          className="info-popup-button"
          onClick={handleClick}
          tabIndex={0}
        >
          <span className="info-popup-button-text">{buttonText}</span>
        </button>
      </div>
    </motion.div>
  );
};

export default InfoPopup;
