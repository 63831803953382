import React, { ChangeEvent } from "react";
import MapFeedback from "../MapFeedback/MapFeedback";
import { storeNames, stores } from "../storeMapUtils";
import "./MapContent.css";

interface IMapContent {
  selectedStore: string;
  handleStoreChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

const MapContent = ({ selectedStore, handleStoreChange }: IMapContent) => {
  return (
    <>
      <select
        className="map-shop-dropdown"
        name="category"
        defaultValue={selectedStore}
        onChange={handleStoreChange}
        aria-label="Endre butikk"
      >
        {storeNames.map((storeName) => (
          <option key={storeName}>{storeName}</option>
        ))}
      </select>
      <img
        className="map-coop-store-img"
        src={stores.get(selectedStore)}
        alt={`Kart over ${selectedStore}`}
      />
      <MapFeedback />
    </>
  );
};
export default MapContent;
