import React, { useEffect, useState, createContext } from "react";
import { getCategories, getProducts } from "client/shoppingListClient";
import { IProductCategoryData, Status } from "types/types";
import ErrorPage from "components/ErrorPage/ErrorPage";

export const productContext = createContext<any>(null);

interface IProductContextProvider {
  children?: JSX.Element;
}

export const ProductContextProvider = ({
  children,
}: IProductContextProvider) => {
  const [status, setStatus] = useState<Status>(Status.Ok);
  const [productCategoryData, setProductCategoryData] = useState<
    IProductCategoryData | undefined
  >(undefined);

  // Load product glossary
  // TO DO: Error handling by try catch
  useEffect(() => {
    Promise.all([getProducts(), getCategories()]).then((responses) => {
      if (!responses) {
        setStatus(Status.Error);
      }
      const products = responses[0];
      const categoryData = responses[1];
      if (products && categoryData) {
        setProductCategoryData({
          productGlossary: products,
          categories: categoryData.categories,
          checkedCategoryName: categoryData.checkedCategoryName,
        });
      }
    });
  }, []);

  if (status !== Status.Ok) {
    return <ErrorPage />;
  }

  return (
    <productContext.Provider value={{ productCategoryData }}>
      {children}
    </productContext.Provider>
  );
};
