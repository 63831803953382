import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";
import worker from "icons/worker.svg";

const NotFound = () => {
  const navigate = useNavigate();

  if (process.env.NODE_ENV !== "development") {
    window.location.href = `${window.location.origin}/404`;
  }

  return (
    <div className="not-found-page-container">
      <div className="not-found-header">
        <h3 className="not-found-header-title">404</h3>
      </div>
      <div className="not-found-container">
        <img className="not-found-container-icon" src={worker} alt="" />
        <h1 className="not-found-container-title">Obs!</h1>
        <h3 className="not-found-container-sub-title">
          Det ser ut til at du har gått deg bort.
        </h3>
        <span className="not-found-container-text">
          Gå tilbake til handlelisten.
        </span>
      </div>
      <button className="not-found-button" onClick={() => navigate("/")}>
        Gå tilbake
      </button>
    </div>
  );
};

export default NotFound;
