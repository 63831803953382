import React, { useCallback, useEffect, useState } from "react";
import userContext from "contexts/userContext";

import { IList, IExtendedList, Status } from "types/types";
import {
  createShoppingList,
  getAllShoppingLists,
  removeShoppingListEditor,
} from "client/shoppingListClient";
import LoadingPage from "components/LoadingPage/LoadingPage";
import ErrorPage from "components/ErrorPage/ErrorPage";
import { writeActiveListToStorage, getActiveList } from "./handleActiveList";
import { getUser } from "./handleUserId";

interface IContextProviderProps {
  children?: JSX.Element;
}

export interface UserContextValues {
  status: Status;
  userID: string;
  activeList: IExtendedList;
  lists: IList[] | undefined;
  addPersonalList: (newList: IList) => void;
  deletePersonalList: (list: IList) => void;
  removeSharedList: (list: IExtendedList) => void;
  switchActiveList: (list: IExtendedList) => void;
  updatePersonalListName: (listId: number, listName: string) => void;
  addSharedListAndSetAsActive: (shareId: string) => void;
}

const UserContextProvider = ({ children }: IContextProviderProps) => {
  const [status, setStatus] = useState<Status>(Status.Loading);
  const [userID, setUserID] = useState<string>("");
  const [lists, setLists] = useState<IList[] | undefined>(undefined);
  const [activeList, setActiveList] = useState<IExtendedList | undefined>(
    undefined
  );

  const setDefaultListToActive = useCallback(() => {
    if (lists && lists.length > 0 && userID) {
      switchActiveList({
        id: lists[0].id,
        name: lists[0].name,
        shareId: lists[0].shareId,
        user: userID,
      });
    }
  }, [lists, userID]);

  // Load local storage user
  useEffect(() => {
    setUserID(getUser());
  }, []);

  // Load local storage user and personal lists
  useEffect(() => {
    if (userID === "") {
      return;
    }
    getAllShoppingLists(userID).then((fetchedLists) => {
      if (fetchedLists) {
        setLists(fetchedLists);
      } else {
        setStatus(Status.Error);
      }
    });
  }, [userID]);

  // Set active list from localstorage
  useEffect(() => {
    // If personal lists and shared lists is loaded, but initial active list is not set
    if (!activeList && lists) {
      const newActiveList = getActiveList(lists, userID);
      newActiveList ? setActiveList(newActiveList) : setDefaultListToActive();
    }
  }, [lists, userID, activeList, setDefaultListToActive]);

  // If personal list is empty, create a new personal list
  useEffect(() => {
    if (lists && lists.length === 0) {
      createShoppingList(userID, "Handleliste").then((newList) => {
        if (newList) {
          setLists([newList]);
        } else {
          setStatus(Status.Error);
        }
      });
    }
  }, [lists, userID]);

  // sort shopping lists by role (owned lists first, editor lists next)
  useEffect(() => {
    if (lists) {
      setLists(
        lists.sort(function (a, b) {
          if (a.role < b.role) {
            return 1;
          }
          return -1;
        })
      );
    }
  }, [lists]);

  // ----- Provided functions -----
  const addPersonalList = (newList: IList) => {
    if (lists) {
      setLists([...lists, newList]);
    }
  };

  const deletePersonalList = (deleteList: IList) => {
    if (!lists) {
      return;
    }
    const updatedPersonalLists = lists.filter(
      (list) => list.id !== deleteList.id
    );
    setLists(updatedPersonalLists);
    if (deleteList.id === activeList?.id) {
      if (updatedPersonalLists.length !== 0) {
        switchActiveList({
          id: updatedPersonalLists[0].id,
          name: updatedPersonalLists[0].name,
          shareId: updatedPersonalLists[0].shareId,
          user: userID,
        });
      } else {
        window.localStorage.removeItem("activelist");
        setActiveList(undefined);
      }
    }
  };

  const addSharedListAndSetAsActive = (shareId: string) => {
    getAllShoppingLists(userID).then((fetchedLists) => {
      if (fetchedLists) {
        const newActiveList: IExtendedList = {
          user: userID,
          ...fetchedLists.filter((list) => list.shareId === shareId)[0],
        };

        setLists(fetchedLists);
        switchActiveList(newActiveList);
      } else {
        setStatus(Status.Error);
      }
    });
  };

  const removeSharedList = (listId: number) => {
    removeShoppingListEditor(userID, listId).then(() => {
      const updatedLists = lists?.filter((list) => list.id !== listId);
      setLists(updatedLists);
      if (activeList?.id === listId) {
        setDefaultListToActive();
      }
    });
  };

  const switchActiveList = (list: IExtendedList) => {
    writeActiveListToStorage(list.id);
    setActiveList(list);
  };

  const updatePersonalListName = (listId: number, listName: string) => {
    const updatedPersonalLists = lists?.map((list) => {
      if (list.id === listId) {
        list.name = listName;
      }
      return list;
    });
    setLists(updatedPersonalLists);

    if (activeList?.id === listId) {
      setActiveList({
        id: listId,
        name: listName,
        shareId: activeList.shareId,
        user: userID,
      });
    }
  };

  if (status === Status.Error) {
    return <ErrorPage />;
  }

  if (!userID || !activeList || !lists) {
    return <LoadingPage />;
  }

  return (
    <userContext.Provider
      value={{
        status,
        userID,
        lists,
        activeList,
        addPersonalList,
        deletePersonalList,
        removeSharedList,
        switchActiveList,
        updatePersonalListName,
        addSharedListAndSetAsActive,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export default UserContextProvider;
