import Headerbutton from "../../Shared/HeaderButton/HeaderButton";
import {
  createSharedLink,
  headerIcons,
  isApple,
  shareNative,
} from "./utils/headerButtonUtils";
import Heading from "../../Heading/Heading";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { IExtendedList } from "../../../types/types";
import copy from "copy-to-clipboard";
import { LiveRegionContext } from "../../App/ContextProviders/LiveRegionContext";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

interface IShoppinglistPageHeading {
  activeList: IExtendedList;
  minimizeHeader: boolean;
  showShareButton: boolean;
  toggleCategorization: () => void;
  showCategorizedList: boolean;
  showMenu: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
}

export const ShoppinglistPageHeading = ({
  activeList,
  minimizeHeader,
  showShareButton,
  showCategorizedList,
  toggleCategorization,
  showMenu,
  setShowMenu,
}: IShoppinglistPageHeading) => {
  const { triggerSharingFeedback, triggerLoginHint } =
    useContext(LiveRegionContext);
  const navigate = useNavigate();
  const location = useLocation();

  const moveToMapPage = () => {
    navigate(
      `${location.pathname}${location.pathname.length > 1 ? "/kart" : "kart"}`
    );
  };

  const handleShareList = () => {
    const shareNativeSuccess = shareNative(activeList.user, activeList.id);
    if (shareNativeSuccess) {
      return;
    }
    const copyLinkSuccess = copy(
      createSharedLink(activeList.user, activeList.id)
    );
    if (copyLinkSuccess) {
      triggerSharingFeedback();
    }
    setTimeout(() => triggerLoginHint(), 10000);
  };

  return (
    <Heading listName={activeList.name} minimizeHeader={minimizeHeader}>
      <>
        {showShareButton && (
          <Headerbutton
            id="share-button"
            icon={
              isApple
                ? headerIcons.get("share_apple")
                : headerIcons.get("share_android")
            }
            selectedIcon={
              isApple
                ? headerIcons.get("share_apple")
                : headerIcons.get("share_android")
            }
            label="Del"
            isActive={false}
            handleClick={handleShareList}
          />
        )}
      </>
      <Headerbutton
        id="categorize-button"
        icon={headerIcons.get("categorizeIcon")}
        selectedIcon={headerIcons.get("categorizeIconSelected")}
        label="Sorter"
        isActive={!showCategorizedList}
        handleClick={() => toggleCategorization()}
      />
      <Headerbutton
        id="map-button"
        icon={headerIcons.get("mapIcon")}
        selectedIcon={headerIcons.get("mapIconSelected")}
        label="Kart"
        isActive={false}
        handleClick={moveToMapPage}
      />
      <Headerbutton
        id="menu-button"
        icon={headerIcons.get("settingsIcon")}
        selectedIcon={headerIcons.get("settingsIconSelected")}
        label="Meny"
        isActive={showMenu}
        handleClick={() => setShowMenu(!showMenu)}
      />
    </Heading>
  );
};
