import { NotFoundError } from "types/errorTypes";

interface IRequest {
  path: string;
  host: string;
  token?: string;
}

interface ISendRequest extends IRequest {
  body: any;
}

export async function get({ path, host, token }: IRequest) {
  const options = {
    headers: createHeaders(token),
    method: "GET",
  };

  const url = `${host}${path}`;
  const response = await fetchData(url, options);
  return response;
}

export async function post({ path, host, body, token }: ISendRequest) {
  const options = {
    headers: createHeaders(token),
    method: "POST",
    body: JSON.stringify(body),
  };
  const url = `${host}${path}`;
  const response = await fetchData(url, options);
  return response;
}

export async function put({ path, host, body, token }: ISendRequest) {
  const options = {
    headers: createHeaders(token),
    method: "PUT",
    body: JSON.stringify(body),
  };
  const url = `${host}${path}`;
  const response = await fetchData(url, options);

  return response;
}

export async function del({ path, host, token }: IRequest) {
  const options = {
    headers: createHeaders(token),
    method: "DELETE",
  };
  const url = `${host}${path}`;
  const response = await fetchData(url, options);
  return response;
}

async function fetchData(url: string, options: any) {
  const response = await fetch(url, options);

  if (response.ok) {
    return response.json();
  }
  if (response.status === 404) {
    throw new NotFoundError("not found");
  }

  throw new Error(response.status + " " + response.statusText);
}

function createHeaders(token?: string) {
  //x-Accept passes to express server, Accept passes to API

  const authHeader = token ? { Authorization: `Bearer ${token}` } : undefined;
  const acceptHeader = authHeader
    ? { Accept: "application/json" }
    : { "x-Accept": "application/json" };

  return {
    ...acceptHeader,
    ...authHeader,
    "Content-Type": "application/json",
  };
}
