import { Collapsible } from "components/Shared/Collapsible/Collapsible";
import Item from "components/ShoppingList/Item/Item";
import React, { useState } from "react";
import { ICategory, IItem } from "types/types";
import "./Category.css";
import { Droppable } from "react-beautiful-dnd";
import { motion } from "framer-motion";

interface ICategoryProps {
  category: ICategory;
  checkedCategoryName: string;
  toggleChecked: (item: IItem) => void;
  deleteItem: (deleteItem: IItem) => void;
  updateItemCategory: (item: IItem, newCategory: string) => void;
}

const Category = ({
  category,
  checkedCategoryName,
  toggleChecked,
  deleteItem,
  updateItemCategory,
}: ICategoryProps) => {
  const [open, setOpen] = useState<boolean>(
    category.name !== checkedCategoryName
  );

  const categoryTitle = (title: string) => {
    const itemCount = category.items.length;
    if (title === checkedCategoryName) {
      return (
        <h3 className="checked-category-title">{`${itemCount} er ${checkedCategoryName.toLowerCase()}`}</h3>
      );
    }
    return (
      <h3 className="category-title">{`${title} ${
        open ? "" : `(${itemCount})`
      }`}</h3>
    );
  };

  return (
    <motion.li
      className="category-list"
      layout="position"
      layoutId={category.name}
    >
      <Collapsible
        collapseTitle={categoryTitle(category.name)}
        open={open}
        toggleOpen={() => setOpen(!open)}
        layoutId={category.name}
      >
        <Droppable droppableId={`droppable-${category.name}`}>
          {(provided) => (
            <ul className={`category-content`} ref={provided.innerRef}>
              {category.items.map((item, index) => (
                <Item
                  item={item}
                  toggleChecked={toggleChecked}
                  key={item.id}
                  deleteItem={deleteItem}
                  index={index}
                  updateItemCategory={updateItemCategory}
                />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </Collapsible>
    </motion.li>
  );
};

export default Category;
