import React from "react";
import "./HeaderButton.css";

interface HeaderButtonProps {
  icon: string | undefined;
  selectedIcon: string | undefined;
  label: string;
  id: string;
  isActive: boolean;
  handleClick: () => void;
}

const Headerbutton = ({
  icon,
  selectedIcon,
  label,
  id,
  isActive,
  handleClick,
}: HeaderButtonProps) => {
  return (
    <button id={id} className="header-button" onClick={handleClick}>
      <img src={isActive ? selectedIcon : icon} alt="" />
      <span
        className={`header-icon-label ${
          isActive ? "header-icon-label-selected" : ""
        }`}
      >
        {label}
      </span>
    </button>
  );
};

export default Headerbutton;
