export function getAuth0Domain() {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_AUTH0_DOMAIN_DEVELOPMENT
  } else if (window.location.href.includes("staging.coop")) {
    return process.env.REACT_APP_AUTH0_DOMAIN_STAGING
  }
  return process.env.REACT_APP_AUTH0_DOMAIN_PRODUCTION;
}

export function getAuth0ClientID() {
  if (process.env.NODE_ENV === "development") {
    return process.env.REACT_APP_AUTH0_CLIENT_ID_DEVELOPMENT
  } else if (window.location.href.includes("staging.coop")) {
    return process.env.REACT_APP_AUTH0_CLIENT_ID_STAGING
  }
  return process.env.REACT_APP_AUTH0_CLIENT_ID_PRODUCTION;
}

