import React, { useEffect } from "react";
import "./PopupWindow.css";
import { motion } from "framer-motion";

interface IPopupWindow {
  closePopup: () => void;
  children: JSX.Element | JSX.Element[];
}

const PopupWindow = ({ closePopup, children }: IPopupWindow) => {
  document.body.style.position = "fixed";
  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent) => {
      const { key } = event;
      if (key === "Escape") {
        closePopup();
        return;
      }
    };

    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [closePopup]);
  const variants = {
    initial: {
      scale: 0.9,
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
    show: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
  };
  return (
    <motion.div
      className="fade-background"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="popup-animation-container"
        initial="initial"
        exit="initial"
        animate="show"
        variants={variants}
        role="dialog"
        aria-modal={true}
      >
        <div className="popup-container">{children}</div>
      </motion.div>
    </motion.div>
  );
};

export default PopupWindow;
