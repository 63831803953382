import React, {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useContext,
  createRef,
} from "react";
import userContext from "contexts/userContext";
import { IList } from "types/types";
import { updateShoppingListName } from "client/shoppingListClient";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import PopupWindow from "components/Shared/PopupWindow/PopupWindow";
import "../PopupWindows.css";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/Shared/Buttons/Buttons";

interface IDeleteListProps {
  shoppingList: IList;
  closeChangeNamePopup: () => void;
}

const ChangeNamePopup = ({
  shoppingList,
  closeChangeNamePopup,
}: IDeleteListProps) => {
  const [shoppingListName, setShoppingListName] = useState<string>("");
  const [showEmptyNameWarning, setShowEmptyNameWarning] =
    useState<boolean>(false);
  const { userID, updatePersonalListName }: UserContextValues =
    useContext(userContext);
  const inputRef = createRef<HTMLInputElement>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    setShoppingListName(input);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleChangeName();
    }
  };

  const handleChangeName = () => {
    if (shoppingListName === "") {
      setShowEmptyNameWarning(true);
      inputRef.current?.focus();
    } else {
      updatePersonalListName(shoppingList.id, shoppingListName);
      updateShoppingListName(userID, shoppingList.id, shoppingListName);
      closeChangeNamePopup();
    }
  };

  return (
    <PopupWindow closePopup={closeChangeNamePopup}>
      <h3 className="popup-header">{`Endre navn på \u00ab${shoppingList.name}\u00bb`}</h3>
      <input
        className={`popup-input${showEmptyNameWarning ? "-warning" : ""}`}
        type="text"
        name="search"
        id="test"
        ref={inputRef}
        placeholder="F.eks. Ukeshandel"
        value={shoppingListName}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        autoCorrect="off"
        autoFocus={true}
      />
      <div
        className={`empty-name-warning ${showEmptyNameWarning ? "" : "hide"}`}
      >
        Obs! Listen mangler navn.
      </div>
      <div className="popup-buttons-container popup-buttons-container-less-space-above">
        <SecondaryButton onClick={closeChangeNamePopup}>Avbryt</SecondaryButton>
        <PrimaryButton id="confirm-change-listname" onClick={handleChangeName}>
          Lagre
        </PrimaryButton>
      </div>
    </PopupWindow>
  );
};

export default ChangeNamePopup;
