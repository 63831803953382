import { IItem, IList, IProduct, IShoppingList } from "types/types";
import {
  ICreateItemResponse,
  ICreateShoppingListResponse,
  IGetCategoriesResponse,
  IGetProductsResponse,
  IShoppingListsDataResponse,
} from "types/responseTypes";
import { del, get, post, put } from "./crud";
import { getBackendUri } from "utils/getBackendUri";

const HOST = getBackendUri();

export const getAllShoppingLists = async (
  userID: string,
  token?: string
): Promise<IList[] | undefined> => {
  try {
    const shoppingLists: IShoppingListsDataResponse = await get({
      host: HOST,
      path: `/users/${userID}/shoppinglists`,
      token: token,
    });

    const result: IList[] = shoppingLists.lists;
    return result;
  } catch (e) {
    console.error("Unable to fetch data: ", e);
    return undefined;
  }
};

export const getShoppingList = async (
  userID: string,
  listID: number,
  token?: string
): Promise<IShoppingList> => {
  const shoppingList: IShoppingList = await get({
    host: HOST,
    path: `/users/${userID}/shoppinglists/${listID}`,
    token: token,
  });

  shoppingList.items = shoppingList.items.map((item) => {
    return castResponseItem(item);
  });
  return shoppingList;
};

const castResponseItem = (item: IItem): IItem => {
  return {
    name: item.name,
    id: Number(item.id),
    category: item.category,
    checked: item.checked,
    quantity: Number(item.quantity),
    note: item.note,
    createTime: new Date(item.createTime),
  };
};

export const createShoppingList = async (
  userID: string,
  listName: string,
  token?: string
): Promise<IList | undefined> => {
  try {
    const newShoppingList: ICreateShoppingListResponse = await post({
      host: HOST,
      path: `/users/${userID}/shoppinglists`,
      body: {
        listName: listName,
      },
      token: token,
    });

    return {
      name: listName,
      shareId: newShoppingList.listShareId,
      id: newShoppingList.listId,
      role: "USER_ROLE_OWNER",
      private: true,
    };
  } catch (e) {
    console.error("Failed to create shoppingList: ", e);
    return undefined;
  }
};

export const updateShoppingListName = async (
  userID: string,
  listID: number,
  listName: string,
  token?: string
): Promise<string | undefined> => {
  try {
    await put({
      host: HOST,
      path: `/users/${userID}/shoppinglists/${listID}`,
      body: {
        name: listName,
      },
      token: token,
    });
    return "ok";
  } catch (e) {
    console.error("Failed to update shopping list: ", e);
    return undefined;
  }
};

export const deleteShoppingList = async (
  userID: string,
  listID: number,
  token?: string
): Promise<string | undefined> => {
  try {
    await del({
      host: HOST,
      path: `/users/${userID}/shoppinglists/${listID}`,
      token: token,
    });

    return "ok";
  } catch (e) {
    console.error("Failed to delete shoppinglist: ", e);
    return undefined;
  }
};

export const createItem = async (
  userID: string,
  listID: number,
  itemName: string,
  quantity: number,
  note: string,
  token?: string
): Promise<IItem | undefined> => {
  try {
    const response: ICreateItemResponse = await post({
      host: HOST,
      path: `/users/${userID}/shoppinglists/${listID}/items`,
      body: {
        itemName: itemName,
        quantity: quantity,
        note: note,
      },
      token: token,
    });
    const correctItem: IItem = castResponseItem(response.item);
    return correctItem;
  } catch (e) {
    console.error("Failed to create item: ", e);
    return undefined;
  }
};

export const deleteItem = async (
  userID: string,
  listID: number,
  itemID: number,
  token?: string
): Promise<string | undefined> => {
  try {
    await del({
      host: HOST,
      path: `/users/${userID}/shoppinglists/${listID}/items/${itemID}`,
      token: token,
    });
    return "ok";
  } catch (e) {
    console.error("failed to delete item ", e);
    return undefined;
  }
};

export const updateItem = async (
  userID: string,
  listID: number,
  item: IItem,
  token?: string
): Promise<string | undefined> => {
  try {
    await put({
      host: HOST,
      path: `/users/${userID}/shoppinglists/${listID}/items`,
      body: item,
      token: token,
    });
    return "ok";
  } catch (e) {
    console.error("failed to update item", e);
    return undefined;
  }
};

export const getProducts = async (
  token?: string
): Promise<IProduct[] | undefined> => {
  try {
    const response: IGetProductsResponse = await get({
      host: HOST,
      path: `/products`,
      token: token,
    });
    return response.productGlossary;
  } catch (e) {
    console.error("failed to get product glossary ", e);
    return undefined;
  }
};

export const getCategories = async (
  token?: string
): Promise<IGetCategoriesResponse | undefined> => {
  try {
    const response: IGetCategoriesResponse = await get({
      host: HOST,
      path: `/categories`,
      token: token,
    });
    return response;
  } catch (e) {
    console.error("failed to get categories ", e);
    return undefined;
  }
};

export const addShoppingListEditor = async (
  userID: string,
  shareID: string
): Promise<boolean> => {
  try {
    await post({
      host: HOST,
      path: `/users/${userID}/shoppinglists/${shareID}`,
      body: undefined,
    });
    return true;
  } catch (e) {
    console.error("failed to add shopping list editor, ", e);
    return false;
  }
};

export const removeShoppingListEditor = async (
  userID: string,
  listID: number
): Promise<boolean> => {
  try {
    await del({
      host: HOST,
      path: `/editors/${userID}/shoppinglists/${listID}`,
    });
    return true;
  } catch (e) {
    console.error("failed to add shopping list editor, ", e);
    return false;
  }
};
