import React, { useContext, useEffect, useRef, useState } from "react";
import "./Menu.css";
import { IList } from "types/types";
import userContext from "contexts/userContext";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import ListOptionPage from "./ListOptionPage/ListOptionPage";
import MainMenu from "./MainMenu/MainMenu";
import { AnimatePresence, motion } from "framer-motion";
import CreateShoppingList from "./PopupWindows/CreateShoppingList/CreateShoppingList";
import DeleteShoppingList from "./PopupWindows/DeleteShoppingList/DeleteShoppingList";
import ChangeNamePopup from "./PopupWindows/ChangeNamePopup/ChangeNamePopup";
import SharedListOptionPage from "./SharedListOptionPage/SharedListOptionPage";
import RemoveSharedShoppingList from "./PopupWindows/RemoveSharedShoppingList/RemoveSharedShoppingList";

interface IMenuProps {
  setShowMenu: (menuVisible: boolean) => void;
  showCoachMarks?: boolean;
}

enum MenuModes {
  MainMenu,
  ListOption,
}

const Menu = ({ setShowMenu, showCoachMarks = false }: IMenuProps) => {
  const [showCreateShoppingListPopUp, setShowCreateShoppingListPopUp] =
    useState<boolean>(false);
  const [showDeleteListPopup, setDeleteListPopup] = useState<boolean>(false);
  const [showChangeNamePopup, setShowChangeNamePopup] =
    useState<boolean>(false);
  const [showRemovedSharedListPopup, setShowRemovedSharedListPopup] =
    useState<boolean>(false);
  const [activeMenuMode, setActiveMenuMode] = useState<MenuModes>(
    MenuModes.MainMenu
  );
  const [listChosen, setListChosen] = useState<IList | undefined>(undefined);
  const [sharedListChosen, setSharedListChosen] = useState<IList | undefined>(
    undefined
  );

  const { switchActiveList }: UserContextValues = useContext(userContext);

  const mainMenuRef = useRef<HTMLDivElement>(null);
  const listOptionRef = useRef<HTMLDivElement>(null);
  // Close Menu when clicked outside Menu
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        mainMenuRef &&
        listOptionRef &&
        !showCreateShoppingListPopUp &&
        !showChangeNamePopup &&
        !showDeleteListPopup &&
        !showRemovedSharedListPopup &&
        !showCoachMarks
      ) {
        const clickOutsideMenuRegistered = !(
          mainMenuRef.current?.contains(event.target as Node) ||
          listOptionRef.current?.contains(event.target as Node)
        );
        if (clickOutsideMenuRegistered) {
          setShowMenu(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    mainMenuRef,
    listOptionRef,
    setShowMenu,
    showCreateShoppingListPopUp,
    showDeleteListPopup,
    showCoachMarks,
    showChangeNamePopup,
    showRemovedSharedListPopup,
  ]);

  const switchToListOptionsPage = (list: IList) => {
    setSharedListChosen(undefined);
    setListChosen(list);
    setActiveMenuMode(MenuModes.ListOption);
  };

  const switchToSharedListOptionsPage = (sharedList: IList) => {
    setListChosen(undefined);
    setSharedListChosen(sharedList);
    setActiveMenuMode(MenuModes.ListOption);
  };

  const returnToMainMenu = () => {
    setActiveMenuMode(MenuModes.MainMenu);
  };

  const handleListOptionAnimationEnd = () => {
    if (activeMenuMode === MenuModes.MainMenu) {
      setSharedListChosen(undefined);
      setListChosen(undefined);
    }
  };

  const menuPageVariants = {
    visible: {
      width: "16rem",
      opacity: 1,
      margin: "1rem 0 2rem 2rem",
      transition: {
        delay: 0.1,
        type: "spring",
        duration: 0.6,
      },
      transitionEnd: {
        display: "block",
      },
    },
    hidden: {
      width: 0,
      opacity: 0,
      margin: "1rem 0 0 0",
      transition: {
        ease: "easeInOut",
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <>
      <div className="invisible-overlay" />
      <div className="menu-container">
        <motion.div
          className="main-page-container"
          key="mainMenuPage"
          variants={menuPageVariants}
          animate={activeMenuMode === MenuModes.MainMenu ? "visible" : "hidden"}
          initial="hidden"
          exit="hidden"
          ref={mainMenuRef}
        >
          <MainMenu
            closeMenu={() => setShowMenu(false)}
            openCreateListPopup={() => setShowCreateShoppingListPopUp(true)}
            setListChosen={switchToListOptionsPage}
            setSharedListChosen={switchToSharedListOptionsPage}
          />
        </motion.div>
        <motion.div
          className="list-options-container"
          key="listOptionPage"
          variants={menuPageVariants}
          animate={
            activeMenuMode === MenuModes.ListOption ? "visible" : "hidden"
          }
          onAnimationEnd={handleListOptionAnimationEnd}
          initial="hidden"
          exit="hidden"
          ref={listOptionRef}
        >
          {listChosen && (
            <ListOptionPage
              listChosen={listChosen}
              returnToMainMenu={returnToMainMenu}
              showDeleteListPopup={() => setDeleteListPopup(true)}
              showChangeNamePopup={() => setShowChangeNamePopup(true)}
            />
          )}
          {sharedListChosen && (
            <SharedListOptionPage
              sharedListChosen={sharedListChosen}
              returnToMainMenu={returnToMainMenu}
              showRemoveSharedListPopup={() =>
                setShowRemovedSharedListPopup(true)
              }
            />
          )}
        </motion.div>
      </div>

      <AnimatePresence>
        {showCreateShoppingListPopUp && (
          <CreateShoppingList
            switchActiveList={switchActiveList}
            closeCreateShoppingList={() => {
              setShowCreateShoppingListPopUp(false);
            }}
          />
        )}
        {showDeleteListPopup && listChosen && (
          <DeleteShoppingList
            shoppingList={listChosen}
            closeDeleteListPopup={() => {
              setDeleteListPopup(false);
            }}
            returnToMainMenu={returnToMainMenu}
          />
        )}
        {showChangeNamePopup && listChosen && (
          <ChangeNamePopup
            shoppingList={listChosen}
            closeChangeNamePopup={() => {
              setShowChangeNamePopup(false);
            }}
          />
        )}
        {showRemovedSharedListPopup && sharedListChosen && (
          <RemoveSharedShoppingList
            sharedShoppingList={sharedListChosen}
            closeRemovedSharedListPopup={() => {
              setShowRemovedSharedListPopup(false);
            }}
            returnToMainMenu={returnToMainMenu}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Menu;
