import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import MapContent from "./MapContent/MapContent";
import MapFeedback from "./MapFeedback/MapFeedback";
import "./StoreMap.css";
import {
  urlParams,
  initializeStore,
  initializeStoreType,
  writeStoreToLocalStorage,
  writeStoreTypeToLocalStorage,
} from "./storeMapUtils";
import StoreTypeMenu from "./StoreTypeMenu/StoreTypeMenu";

const StoreMap = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStoreType, setSelectedStoreType] = useState<string>("");
  const [selectedStore, setSelectedStore] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    let queryStore = urlSearchParams.get(urlParams.STORE);
    let queryStoreType = urlSearchParams.get(urlParams.STORETYPE);
    let initialStoreType: string;
    let initialStore: string | undefined;

    if (queryStoreType) {
      initialStoreType = queryStoreType;
      initialStore = queryStore ? queryStore : initializeStore(queryStoreType);
    } else {
      initialStoreType = initializeStoreType();
      initialStore = initializeStore(initialStoreType);
    }
    if (initialStore) {
      writeStoreToLocalStorage(initialStoreType, initialStore);
      urlSearchParams.set(urlParams.STORE, initialStore);
    }
    writeStoreTypeToLocalStorage(initialStoreType);
    urlSearchParams.set(urlParams.STORETYPE, initialStoreType);
    setSearchParams(urlSearchParams.toString());

    setSelectedStore(initialStore);
    setSelectedStoreType(initialStoreType);
  }, [setSearchParams, location.search]);

  const handleStoreChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const updatedStore = e.target.value;
    searchParams.set(urlParams.STORE, updatedStore);
    setSearchParams(searchParams.toString());
    writeStoreToLocalStorage(selectedStoreType, updatedStore);
    setSelectedStore(updatedStore);
  };

  const handleStoreTypeChange = (updatedStoreType: string) => {
    searchParams.set(urlParams.STORETYPE, updatedStoreType);
    const currentStore = initializeStore(updatedStoreType);
    if (currentStore) {
      searchParams.set(urlParams.STORE, currentStore);
      writeStoreToLocalStorage(selectedStoreType, currentStore);
    } else {
      searchParams.delete(urlParams.STORE);
    }
    setSearchParams(searchParams.toString());
    writeStoreTypeToLocalStorage(updatedStoreType);
    setSelectedStoreType(updatedStoreType);
    setSelectedStore(currentStore);
  };

  return (
    <div
      className={`map-page-container ${
        !selectedStore ? "map-container-illustration" : ""
      }`}
    >
      <StoreTypeMenu
        selectedStoreType={selectedStoreType}
        handleStoreTypeChange={handleStoreTypeChange}
      />
      <div className={`map-container `}>
        {selectedStore && (
          <MapContent
            selectedStore={selectedStore}
            handleStoreChange={handleStoreChange}
          />
        )}
        {!selectedStore && <MapFeedback />}
      </div>
    </div>
  );
};

export default StoreMap;
