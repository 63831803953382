import React, { createContext, useState } from "react";
import {useAuth0} from "@auth0/auth0-react";

interface ILiveRegionContextProvider {
  children: React.ReactNode;
}

interface ILiveRegionContext {
  closeWindow: () => void;
  triggerAddToHomeScreenHint: () => void;
  triggerLoginHint: () => void;
  triggerSharingFeedback: () => void;
  triggerLoggedInFeedback: () => void;
  activeWindow: ActiveWindow | undefined;
}

export enum ActiveWindow {
  AddToHomeScreenHint = "addToHomeScreenHint",
  LoginHint = "loginHint",
  SharingFeedback = "sharingFeedback",
  LoggedInFeedback = "loggedInFeedback",
}

export const LiveRegionContext = createContext<ILiveRegionContext>({
  closeWindow: () => {},
  triggerAddToHomeScreenHint: () => {},
  triggerLoginHint: () => {},
  triggerLoggedInFeedback: () => {},
  triggerSharingFeedback: () => {},
  activeWindow: undefined,
});

export const LiveRegionContextProvider = ({
  children,
}: ILiveRegionContextProvider) => {
  const [activeWindow, setActiveWindow] = useState<ActiveWindow | undefined>();
  const { isAuthenticated } = useAuth0();

  const closeWindow = () => setActiveWindow(undefined);

  const triggerAddToHomeScreenHint = () => {
    if (!activeWindow) {
      setActiveWindow(ActiveWindow.AddToHomeScreenHint);
    }
  };

  const triggerSharingFeedback = () => {
    setActiveWindow(ActiveWindow.SharingFeedback);
  };

  const triggerLoginHint = () => {
    if (!activeWindow && !isAuthenticated) {
      setActiveWindow(ActiveWindow.LoginHint);
    }
  }

  const triggerLoggedInFeedback = () => {
    setActiveWindow(ActiveWindow.LoggedInFeedback);
  }

  return (
    <LiveRegionContext.Provider
      value={{
        activeWindow,
        closeWindow,
        triggerAddToHomeScreenHint,
        triggerSharingFeedback,
        triggerLoginHint,
        triggerLoggedInFeedback,
      }}
    >
      {children}
    </LiveRegionContext.Provider>
  );
};
