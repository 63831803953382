import { IExtendedList, IList, IStoreActiveList } from "types/types";

export const writeActiveListToStorage = (listID: number) => {
  const newActiveList: IStoreActiveList = {
    listID: listID,
  };
  window.localStorage.setItem("activelist", JSON.stringify(newActiveList));
};

export const getActiveList = (
  lists: IList[] | undefined,
  userID: string
): IExtendedList | undefined => {
  const storedActiveList = getActiveListFromStorage();
  if (!storedActiveList) {
    return undefined;
  }
  return findActiveList(lists, userID, storedActiveList.listID);
};

const getActiveListFromStorage = (): IStoreActiveList | undefined => {
  const storedActiveList = window.localStorage.getItem("activelist");
  if (!storedActiveList) {
    return undefined;
  }
  return JSON.parse(storedActiveList);
};

const findActiveList = (
  lists: IList[] | undefined,
  userID: string,
  listID: number
): IExtendedList | undefined => {
  const list = lists?.find((list) => list.id === listID);
  if (list) {
    // If list exists, set to active
    return {
      user: userID,
      name: list.name,
      id: list.id,
      shareId: list.shareId,
    };
  }
  return undefined;
};
