import React from "react";
import { IDragPosition, IItem, IItemList } from "types/types";
import Item from "components/ShoppingList/Item/Item";
import DeleteItemsButton from "../DeleteItemsButton/DeleteItemsButton";
import ShoppingListIllustration from "../ShoppingListIllustration/ShoppingListIllustration";
import "./UncategorizedList.css";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

interface IUncategorizedListProps {
  itemList: IItemList;
  toggleChecked: (checkedItem: IItem) => void;
  deleteCheckedItems: () => void;
  deleteItem: (deleteItem: IItem) => void;
  reorderItemList: (destination: IDragPosition, source: IDragPosition) => void;
  updateItemCategory: (item: IItem, newCategory: string) => void;
}

const UncategorizedList = ({
  itemList,
  toggleChecked,
  deleteCheckedItems,
  deleteItem,
  reorderItemList,
  updateItemCategory,
}: IUncategorizedListProps) => {
  if (itemList.items.length === 0) {
    return <ShoppingListIllustration />;
  }
  const anyItemsChecked = itemList.items
    .map((item) => item.checked)
    .includes(true);

  const ItemsCheckedCount = itemList.items.filter(
    (item) => item.checked
  ).length;

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;
    reorderItemList(destination, source);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="uncategorized-list-container-header">
        <h3
          className="uncategorized-list-info-text"
          id="uncategorized-list-info-text"
        >
          VARER I DIN HANDLELISTE
        </h3>
        <div className="uncategorized-list-item-counter">
          {`${ItemsCheckedCount} / ${itemList.items.length}`}
        </div>
      </div>
      <ul
        className="uncategorized-list-container"
        aria-labelledby="uncategorized-list-info-text"
      >
        <Droppable droppableId={`droppable-productList`}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {itemList.items.map((item, index) => (
                <Item
                  item={item}
                  toggleChecked={toggleChecked}
                  key={item.id}
                  deleteItem={deleteItem}
                  index={index}
                  updateItemCategory={updateItemCategory}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {anyItemsChecked && (
          <DeleteItemsButton deleteCheckedItems={deleteCheckedItems} />
        )}
      </ul>
    </DragDropContext>
  );
};

export default UncategorizedList;
