import React from "react";
import "./Heading.css";
import "common/Fonts.css";

interface IHeadingProps {
  listName: string;
  minimizeHeader?: boolean;
  children: JSX.Element | JSX.Element[];
}

const Heading = ({ listName, minimizeHeader, children }: IHeadingProps) => {
  return (
    <>
      <header className="header-container">
        <div
          className={`header-container-inner header-container-inner-${
            minimizeHeader ? "minimize" : "expand"
          }`}
        >
          <div className="header-title-container">
            <h1 className="title header-title">{listName}</h1>
          </div>
          <div className="header-buttons-container">{children}</div>
        </div>
      </header>
    </>
  );
};

export default Heading;
