import React from "react";
import "./WarningMessage.css";
import warningIcon from "icons/warning.svg";
import { motion } from "framer-motion";

interface IWarningMessageProps {
  message: string;
}

const variants = {
  initial: {
    opacity: 0,
    x: "-55%",
  },
  show: {
    opacity: 1,
    x: "-50%",
    transition: {
      type: "tween",
      duration: 0.4,
    },
  },
  close: {
    opacity: 0,
    x: "-45%",
    transition: {
      type: "tween",
      duration: 0.4,
    },
  },
};

const WarningMessage = ({ message }: IWarningMessageProps) => {
  return (
    <motion.div
      className="warning-container"
      initial="initial"
      animate="show"
      exit="close"
      variants={variants}
    >
      <span className="warning-text">{message}</span>
      <img className="warning-icon" src={warningIcon} alt="Advarsel" />
    </motion.div>
  );
};

export default WarningMessage;
