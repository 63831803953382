import React, { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LiveRegionContext } from "./ContextProviders/LiveRegionContext";
import { Navigate } from "react-router-dom";
import ErrorPage from "../ErrorPage/ErrorPage";

export const AuthenticationCallback = () => {
  const { isAuthenticated, error, loginWithRedirect } = useAuth0();
  const { triggerLoggedInFeedback } = useContext(LiveRegionContext);

  if (isAuthenticated) {
    triggerLoggedInFeedback();
  }

  //TODO: Risk authentication loop here, what is a good user flow if authentication fails?
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error, loginWithRedirect]);

  if (error) {
    return <ErrorPage />;
  }
  return <Navigate to="/logintest" />;
};
