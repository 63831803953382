import share_apple from "icons/header/share.svg";
import share_android from "icons/header/share_android.svg";
import settingsIconSelected from "icons/more_horizontal_white.svg";
import settingsIcon from "icons/more_horizontal_blue.svg";
import categorizeIconSelected from "icons/header/categorize_icon.svg";
import categorizeIcon from "icons/header/categorize_icon_selected.svg";
import mapIcon from "icons/header/map_blue.svg";
import mapIconSelected from "icons/header/map_white.svg";

export const headerIcons = new Map<string, string>([
  ["share_apple", share_apple],
  ["share_android", share_android],
  ["settingsIconSelected", settingsIconSelected],
  ["settingsIcon", settingsIcon],
  ["categorizeIconSelected", categorizeIconSelected],
  ["categorizeIcon", categorizeIcon],
  ["mapIcon", mapIcon],
  ["mapIconSelected", mapIconSelected],
]);

export const isApple = /iPad|iPhone|iPod|MacIntel/.test(
  window.navigator.platform
);

export const shareNative = (userID: string, listID: number): boolean => {
  if (navigator.share) {
    navigator.share({
      title: "Handleliste",
      text: `Hei! Her får du lenke til å dele handleliste med meg.`,
      url: createSharedLink(userID, listID),
    });
    return true;
  }
  return false;
};

export const createSharedLink = (userID: string, listID: number) => {
  const path = window.location.pathname.replace("/", "").replace("app", "");
  const link =
    window.location.origin +
    (path === "" ? "" : "/") +
    path +
    `/share/${userID}/${listID}` +
    "/?utm_source=shoppinglist&utm_medium=web&utm_campaign=share&utm_content=januar"; // Google campaign url
  return link;
};
