import React from "react";
import "./DeleteItemsButton.css";

interface IDeleteButtonProps {
  deleteCheckedItems: () => void;
}

const DeleteItemsButton = ({ deleteCheckedItems }: IDeleteButtonProps) => {
  return (
    <button
      className="delete-items-button"
      onClick={() => deleteCheckedItems()}
    >
      Slett avkrysset
    </button>
  );
};

export default DeleteItemsButton;
