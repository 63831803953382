import { Settings } from "types/types";

export const getSettingsFromStorage = (): Settings => {
  const storedValue = window.localStorage.getItem("categorizelist");
  if (storedValue) {
    const categorizeList = JSON.parse(storedValue);
    return {
      showCategorization: categorizeList,
    };
  } else {
    writeCategorizeToStorage(true);
    return {
      showCategorization: true,
    };
  }
};

export const writeCategorizeToStorage = (showCategorization: boolean) => {
  localStorage.setItem("categorizelist", JSON.stringify(showCategorization));
};
