import React, { useContext } from "react";
import userContext from "contexts/userContext";
import { IList } from "types/types";
import PopupWindow from "components/Shared/PopupWindow/PopupWindow";
import DeleteButton from "components/Shared/DeleteButton/DeleteButton";
import "../PopupWindows.css";
import { SecondaryButton } from "components/Shared/Buttons/Buttons";

interface IRemoveSharedShoppingList {
  sharedShoppingList: IList;
  closeRemovedSharedListPopup: () => void;
  returnToMainMenu: () => void;
}

const RemoveSharedShoppingList = ({
  sharedShoppingList,
  closeRemovedSharedListPopup,
  returnToMainMenu,
}: IRemoveSharedShoppingList) => {
  const { removeSharedList } = useContext(userContext);

  const handleRemoveSharedList = () => {
    removeSharedList(sharedShoppingList.id);
    returnToMainMenu();
    closeRemovedSharedListPopup();
  };

  return (
    <PopupWindow closePopup={closeRemovedSharedListPopup}>
      <h3 className="popup-header">{`Vil du fjerne \u00ab${sharedShoppingList.name}\u00bb?`}</h3>
      <div className="popup-buttons-container">
        <SecondaryButton onClick={closeRemovedSharedListPopup}>
          Avbryt
        </SecondaryButton>
        <DeleteButton
          id="confirm-remove-list-click"
          onClick={handleRemoveSharedList}
          text="Fjern"
        />
      </div>
    </PopupWindow>
  );
};

export default RemoveSharedShoppingList;
