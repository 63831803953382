import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./components/App/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Auth0Provider } from "@auth0/auth0-react";
import { getAuth0ClientID, getAuth0Domain } from "./utils/getAuth0Details";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={getAuth0Domain() || ""}
      clientId={getAuth0ClientID() || ""}
      redirectUri={`${window.location.origin}/callback`}
      audience="https://api.coop.no"
      scope=""
      useRefreshTokens={true}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
 </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
