import React, { ForwardedRef } from "react";
import "./Buttons.css";
import closeIcon from "icons/close-white.svg";

export const PrimaryButton = React.forwardRef(
  (
    {
      children,
      className,
      ...props
    }: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    ref?: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      {...props}
      className={`primary-button button ${className}`}
      ref={ref}
    >
      {children}
    </button>
  )
);

export const SecondaryButton = React.forwardRef(
  (
    {
      children,
      className,
      ...props
    }: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    ref?: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      {...props}
      className={`secondary-button button ${className}`}
      ref={ref}
    >
      {children}
    </button>
  )
);

export const CloseWindowButton = React.forwardRef(
  (
    {
      className,
      ...props
    }: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    ref?: ForwardedRef<HTMLButtonElement>
  ) => (
    <button {...props} className={`window-close-button ${className}`} ref={ref}>
      <img src={closeIcon} alt="Lukk vindu" />
    </button>
  )
);
