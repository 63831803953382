import calculateStringSimilarity from "./calculateStringSimilarity";
import { IProductCategoryData, ISearchResult } from "../types/types";

const doTypeAheadSearch = (
  query: string,
  productCategoryData: IProductCategoryData,
  maxResults: number = 10
): string[] => {
  const result: ISearchResult[] = productCategoryData.productGlossary.map(
    (productCategory) => ({
      foodData: productCategory,
      similarity: calculateStringSimilarity(
        query.toLocaleLowerCase(),
        productCategory.name.toLowerCase()
      ),
    })
  );

  const sortedResults = result
    .filter((searchResult) => searchResult.similarity > 0)
    .sort(function (a, b) {
      return (
        productCategoryData.categories.indexOf(a.foodData.category) -
        productCategoryData.categories.indexOf(b.foodData.category)
      );
    })
    .sort((a, b) => b.similarity - a.similarity)
    .map((searchResult) => searchResult.foodData.name);

  return sortedResults.slice(0, maxResults);
};

export default doTypeAheadSearch;
