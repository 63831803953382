import React from "react";
import {
  CloseWindowButton,
  PrimaryButton,
} from "components/Shared/Buttons/Buttons";
import "./LoginHint.css";
import { LiveWindow } from "components/Shared/LiveWindow/LiveWindow";
import { useAuth0 } from "@auth0/auth0-react";

interface ILoginHint {
  closeWindow: () => void;
}

export const LoginHint = ({ closeWindow }: ILoginHint) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <LiveWindow>
      <div className="loginhint-close-button">
        <CloseWindowButton onClick={closeWindow} />
      </div>
      <h3 className="loginhint-title">
        Logg inn for å få handlelisten hvor enn du er!
      </h3>
      <p className="loginhint-text">
        Da kan du lagre og enkelt finne igjen listene dine på mobil, nettbrett
        og datamaskin
      </p>
      <PrimaryButton
        className="loginhint-button"
        onClick={() => loginWithRedirect()}
      >
        Logg inn
      </PrimaryButton>
    </LiveWindow>
  );
};
