import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "components/NotFound/NotFound";
import UserContextProvider from "./ContextProviders/UserContextProvider";
import ShoppinglistPage from "components/Pages/ShoppinglistPage/ShoppinglistPage";
import ShareList from "./ShareList/ShareList";
import { ProductContextProvider } from "./ContextProviders/ProductContext";
import MapPage from "components/Pages/MapPage/MapPage";
import { LiveRegionContextProvider } from "./ContextProviders/LiveRegionContext";
import { AuthenticationCallback } from "./AuthenticationCallback";
import InviteUserToList from "./ShareList/InviteUserToList";

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
if (isMobile) {
  document.body.style.setProperty("--focus-color", "var(--focus-color-mobile)");
}

const App = () => {
  return (
    <main
      style={{
        position: "fixed",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        height: window.innerHeight,
      }}
    >
      <Routes>
        <Route path="/share/:userid/:listid" element={<ShareList />} />
        <Route path="/*" element={<ShoppingListRoutes />} />
      </Routes>
    </main>
  );
};

const ShoppingListRoutes = () => {
  return (
    <ProductContextProvider>
      <UserContextProvider>
        <LiveRegionContextProvider>
          <Routes>
            <Route path="/" element={<ShoppinglistPage />} />
            <Route path="/app" element={<ShoppinglistPage />} />
            <Route path="/logintest" element={<ShoppinglistPage />} />
            <Route path="/share/:shareID" element={<InviteUserToList />} />
            <Route path="/kart" element={<MapPage />} />
            <Route path="/callback" element={<AuthenticationCallback />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </LiveRegionContextProvider>
      </UserContextProvider>
    </ProductContextProvider>
  );
};

export default App;
