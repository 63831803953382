import React from "react";
import { IList } from "types/types";
import backarrow from "icons/backArrow.svg";

import del from "icons/delete.svg";
import pencil from "icons/edit_pencil.svg";
import "./ListOptionPage.css";
import "../Menu.css";

interface IListOptionsProps {
  listChosen: IList | undefined;
  returnToMainMenu: () => void;
  showDeleteListPopup: () => void;
  showChangeNamePopup: () => void;
}

const ListOptionPage = ({
  listChosen,
  returnToMainMenu,
  showDeleteListPopup,
  showChangeNamePopup,
}: IListOptionsProps) => {
  return (
    <>
      <div className="list-options">
        <div className="menu-element">
          <h3 className="menu-header" id="list-options-menu-header">
            {listChosen ? listChosen.name : ""}
          </h3>
        </div>
        <hr className="horizontal-line" />
        <ul
          className="list-options-list"
          aria-labelledby="list-options-menu-header"
        >
          <li className="list-option-element">
            <button
              className="menu-option-button"
              onClick={() => {
                returnToMainMenu();
              }}
            >
              <img className="menu-option-icon" src={backarrow} alt="" />
              <span>Tilbake</span>
            </button>
          </li>
          <hr className="horizontal-line" />
          <li id="change-list-name" className="list-option-element">
            <button
              className="menu-option-button"
              onClick={showChangeNamePopup}
            >
              <img className="menu-option-icon" src={pencil} alt="" />
              <span>Endre navn</span>
            </button>
          </li>
          <hr className="horizontal-line" />
          <li id="delete-list" className="list-option-element">
            <button
              className="delete-option-button"
              onClick={showDeleteListPopup}
            >
              <img className="menu-option-icon" src={del} alt="" />
              <span className="menu-element-button-delete">Slett liste</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ListOptionPage;
