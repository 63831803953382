import React, { useContext, useEffect, useState } from "react";
import userContext from "contexts/userContext";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import { productContext } from "components/App/ContextProviders/ProductContext";
import {
  getSettingsFromStorage,
  writeCategorizeToStorage,
} from "./utils/localStorageUtils";
import { AnimatePresence } from "framer-motion";
import Menu from "components/Menu/Menu";
import ShoppingList from "components/ShoppingList/ShoppingList";
import { LiveRegion } from "components/Shared/LiveRegion";
import { ShoppinglistPageHeading } from "./ShoppinglistPageHeading";

const ShoppingListPage = () => {
  const [showCategorizedList, setShowCategorizedList] = useState<boolean>(true);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [inSearchMode, setInSearchMode] = useState<boolean>(false);
  const { activeList }: UserContextValues = useContext(userContext);
  const { productCategoryData } = useContext(productContext);

  useEffect(() => {
    const { showCategorization } = getSettingsFromStorage();
    setShowCategorizedList(showCategorization);
  }, []);

  const toggleCategorization = () => {
    writeCategorizeToStorage(!showCategorizedList);
    setShowCategorizedList(!showCategorizedList);
  };

  return (
    <>
      <ShoppinglistPageHeading
        activeList={activeList}
        minimizeHeader={inSearchMode}
        showShareButton={false} //må fikses
        showCategorizedList={showCategorizedList}
        toggleCategorization={toggleCategorization}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
      <AnimatePresence>
        {showMenu && <Menu setShowMenu={setShowMenu} key="menu" />}
      </AnimatePresence>
      {activeList && productCategoryData && (
        <ShoppingList
          list={activeList}
          productCategoryData={productCategoryData}
          showCategories={showCategorizedList}
          inSearchMode={setInSearchMode}
        />
      )}
      <LiveRegion />
    </>
  );
};

export default ShoppingListPage;
