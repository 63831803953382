import React from "react";
import "./DeleteButton.css";

interface IDeleteButton {
  onClick: () => void;
  id: string;
  text?: string;
}

const DeleteButton = ({ onClick, id, text }: IDeleteButton) => {
  return (
    <button className="delete-button" id={id} onClick={onClick}>
      {text ? text : "Slett"}
    </button>
  );
};

export default DeleteButton;
