import React from "react";
import "./LiveWindow.css";

interface ILiveWindow {
  children: React.ReactNode;
  className?: string;
}

export const LiveWindow = (props: ILiveWindow & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={`live-window-container ${props.className}`}
      role="dialog"
      aria-modal="true"
      aria-live="polite"
    >
      {props.children}
    </div>
  )
}
