import React, { ChangeEvent, KeyboardEvent, useEffect, useRef } from "react";
import { useState } from "react";
import "./Search.css";
import doTypeAheadSearch from "utils/doTypeAheadSearch";
import searchIcon from "icons/search.svg";
import { IProductCategoryData } from "types/types";

interface ISearchProps {
  productCategoryData: IProductCategoryData;
  hideSearch: () => void;
  addItem: (productName: string) => void;
  showSearch: () => void;
  inSearchMode: (minimize: boolean) => void;
}

export const Search = ({
  productCategoryData,
  hideSearch,
  addItem,
  showSearch,
  inSearchMode,
}: ISearchProps) => {
  const [searchString, setSearchString] = useState<string>("");
  const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
  const searchInput = useRef<HTMLInputElement>(null);
  const [searchIsFocused, setSearchIsFocused] = useState<boolean>(false);

  useEffect(() => {
    inSearchMode(searchIsFocused);
  }, [searchIsFocused, inSearchMode]);

  const addItemToShoppingList = (itemName: string) => {
    if (itemName.trim().length === 0) {
      return;
    }
    addItem(itemName.trim());
    setSearchString("");
    hideSearch();
    setSearchSuggestions([]);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    if (input.length > 0) {
      const result: string[] = doTypeAheadSearch(
        input.trim(),
        productCategoryData
      );
      let filteredResult: string[] = [];
      if (result.length > 0) {
        filteredResult =
          result[0].toLowerCase() === input.toLowerCase()
            ? result.slice(1)
            : result;
      }

      setSearchSuggestions([input, ...filteredResult]);
      showSearch();
    } else {
      setSearchSuggestions([]);
      hideSearch();
    }
    setSearchString(input);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        let input = (e.target as HTMLInputElement).value;
        addItemToShoppingList(input);
        break;
      default:
        return;
    }
  };

  const cancelSearch = () => {
    setSearchString("");
    hideSearch();
    setSearchSuggestions([]);
    setSearchIsFocused(false);
  };

  const setInputFocus = () => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
  };

  return (
    <>
      <div className="search-field-container">
        <div className="search-field-wrapper">
          <form
            className={`search-field ${
              searchIsFocused ? "search-field-focus" : ""
            }`}
          >
            <img className="search-icon" src={searchIcon} alt="søke ikon" />
            <label htmlFor="search-input" className="search-input-label">
              Legg til vare
            </label>
            <input
              className="search-input"
              type="text"
              name="search"
              id="search-input"
              ref={searchInput}
              placeholder="Legg til vare"
              value={searchString}
              onFocus={() => setSearchIsFocused(true)}
              onBlur={() => setSearchIsFocused(false)}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              autoCorrect="off"
              autoFocus={false}
            />
          </form>
          <button
            className={`search-cancel-button search-cancel-button-${
              searchIsFocused ? "expand" : "minimize"
            }`}
            onClick={() => cancelSearch()}
            tabIndex={-1}
          >
            <span className="search-cancel-button-text">Vis</span>
            <span className="search-cancel-button-text">meny</span>
          </button>
        </div>
      </div>

      {searchSuggestions.length > 0 && (
        <div className="suggestion-container">
          <h3
            className="suggestion-header-hide"
            id="suggestions-desciption"
          >{`Forslag på varer du kan legge til`}</h3>
          <ul
            className="suggestion-list"
            aria-labelledby="suggestions-desciption"
          >
            {searchSuggestions.map((itemName, i) => {
              return (
                <button
                  id={`${i > 0 ? "search-generated-item" : ""}`}
                  className="suggested-item"
                  key={i}
                  onClick={() => {
                    addItemToShoppingList(itemName);
                    setInputFocus();
                  }}
                >
                  <span className="suggestion-name">{itemName}</span>
                  <span className="add-suggestion-button">Legg til</span>
                </button>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default Search;
