import React, { useEffect, useContext } from "react";
import InfoPopup from "components/Shared/InfoPopup/InfoPopup";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import userContext from "contexts/userContext";

interface ShareFeedbackProps {
  closeShareFeedback: () => void;
}

const ShareFeedback = ({ closeShareFeedback }: ShareFeedbackProps) => {
  const { activeList }: UserContextValues = useContext(userContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      closeShareFeedback();
    }, 5000);
    return () => clearTimeout(timer);
  }, [closeShareFeedback]);

  return (
    <InfoPopup
      message={`Delelink til \u00ab${activeList.name}\u00bb har blitt kopiert til din utklippstavle`}
      buttonText="LUKK"
      handleClick={closeShareFeedback}
    />
  );
};

export default ShareFeedback;
