import React from "react";
import { storeType, storeTypeNames } from "../storeMapUtils";
import "./StoreTypeMenu.css";

interface IStoreTypeMenu {
  selectedStoreType: string;
  handleStoreTypeChange: (updatedStoreType: string) => void;
}

const StoreTypeMenu = ({
  selectedStoreType,
  handleStoreTypeChange,
}: IStoreTypeMenu) => {
  return (
    <div className="map-header">
      <div className="map-coop-store-type-container">
        {storeTypeNames.map((storeTypeName) => (
          <button
            key={storeTypeName}
            className={`map-coop-store-type-button ${
              selectedStoreType === storeTypeName
                ? "map-coop-store-type-button-underline"
                : ""
            }`}
            onClick={() => handleStoreTypeChange(storeTypeName)}
          >
            <img
              className="map-coop-store-type-img"
              src={storeType.get(storeTypeName)}
              alt={storeTypeName}
            />
          </button>
        ))}
      </div>
    </div>
  );
};
export default StoreTypeMenu;
