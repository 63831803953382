import React, { useContext } from "react";
import AddToHomescreen from "../Pages/ShoppinglistPage/AddToHomescreen/AddToHomescreen";
import {
  ActiveWindow,
  LiveRegionContext,
} from "../App/ContextProviders/LiveRegionContext";
import ShareFeedback from "../Pages/ShoppinglistPage/ShareFeedback/ShareFeedback";
import { AnimatePresence } from "framer-motion";
import { LoginHint } from "../Pages/ShoppinglistPage/LoginHint/LoginHint";
import { LoggedInFeedback } from "../Pages/ShoppinglistPage/LoginHint/LoggedInFeedback";

export const LiveRegion = () => {
  const { activeWindow, closeWindow } = useContext(LiveRegionContext);

  return (
    <AnimatePresence aria-live="polite">
      <AddToHomescreen
        showAddToHomeScreen={true} // needs handling later
        closeAddToHomeScreen={closeWindow}
      />
      {ActiveWindow.SharingFeedback === activeWindow && (
        <ShareFeedback closeShareFeedback={closeWindow} />
      )}
      {window.location.pathname.includes("logintest") &&
        ActiveWindow.LoginHint === activeWindow && (
          <LoginHint closeWindow={closeWindow} />
        )}
      {ActiveWindow.LoggedInFeedback === activeWindow && (
        <LoggedInFeedback closeWindow={closeWindow} />
      )}
    </AnimatePresence>
  );
};
