import React, { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ShoppingList.css";
import {
  IExtendedList,
  IItem,
  IProductCategoryData,
  Status,
} from "types/types";

import CategoryList from "components/ShoppingList/CategoryList/CategoryList";
import Search from "components/ShoppingList/Search/Search";
import UncategorizedList from "./UncategorizedList/UncategorizedList";
import { createItem, updateItem, deleteItem } from "client/shoppingListClient";

import ErrorPage from "components/ErrorPage/ErrorPage";
import LoadingPage from "components/LoadingPage/LoadingPage";
import WarningMessage from "components/Shared/WarningMessage/WarningMessage";
import RecommendedList from "./RecommendedList/RecommendedList";
import { AnimatePresence } from "framer-motion";
import InfoPopup from "components/Shared/InfoPopup/InfoPopup";
import useItemList from "./useItemList";
import { LiveRegionContext } from "../App/ContextProviders/LiveRegionContext";

interface IShoppingListProps {
  list: IExtendedList;
  productCategoryData: IProductCategoryData;
  showCategories: boolean;
  inSearchMode: (minimize: boolean) => void;
}

export const ShoppingList = ({
  list,
  productCategoryData,
  showCategories,
  inSearchMode,
}: IShoppingListProps) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showConnectionError, setShowConnectionError] =
    useState<boolean>(false);
  const [recentCheckedItem, setRecentCheckedItem] = useState<undefined | IItem>(
    undefined
  );
  const [recentDeletedItems, setRecentDeletedItems] = useState<
    IItem[] | undefined
  >(undefined);

  const {
    listStatus,
    itemList,
    addItem,
    addItems,
    updateItemCategory,
    toggleCheckedItem,
    deleteItemFromItemList,
    deleteCheckedItems,
    reorderItemList,
  } = useItemList();

  const location = useLocation();
  const coopCategories = useMemo(
    () => [
      ...productCategoryData.categories,
      productCategoryData.checkedCategoryName,
    ],
    [productCategoryData]
  );

  const checkedCategoryName = useMemo(
    () => productCategoryData.checkedCategoryName,
    [productCategoryData]
  );

  const { triggerAddToHomeScreenHint } = useContext(LiveRegionContext);

  useEffect(() => {
    // Switch background container color if recommended container is visible. Show correct background-color on scroll bounce.
    const scrollableContainer = document.getElementById("shoppinglist-content");
    const listener = (e: any) => {
      if (scrollableContainer) {
        if (scrollableContainer.scrollTop > 50) {
          document.body.style.setProperty(
            "--default-background-color-body",
            "var(--coop-gray)"
          );
        } else {
          document.body.style.setProperty(
            "--default-background-color-body",
            "var(--coop-light-blue)"
          );
        }
      }
    };
    scrollableContainer?.addEventListener("scroll", listener);
    return () => {
      scrollableContainer?.removeEventListener("scroll", listener);
    };
  });

  // Set timeout on showing regret checked item option
  useEffect(() => {
    const timer = setTimeout(() => setRecentCheckedItem(undefined), 5000);
    return () => clearTimeout(timer);
  }, [recentCheckedItem]);

  // Set timer to delete items after regret delete option is closed
  useEffect(() => {
    const timer = setTimeout(() => {
      recentDeletedItems?.forEach((item) => {
        deleteItem(list.user, list.id, item.id);
      });
      setRecentDeletedItems(undefined);
    }, 5000);
    return () => clearTimeout(timer);
  }, [recentDeletedItems, list]);

  const updateListsWithNewItem = (item: IItem) => {
    if (itemList.items.length + 1 === 3) {
      triggerAddToHomeScreenHint();
    }
    addItem(item);
  };

  const handleAddItem = (inputItemName: string) => {
    if (inputItemName.trim().length === 0) {
      return;
    }
    let quantity = 1;
    const itemName = inputItemName
      .split(" ")
      .map((word) => {
        if (word.match(/^[0-9]+$/) != null) {
          quantity = parseInt(word);
          return "";
        } else {
          return word;
        }
      })
      .join(" ")
      .trim();

    const note = "";
    if (itemList) {
      createItem(list.user, list.id, itemName, quantity, note).then((item) => {
        if (item) {
          updateListsWithNewItem(item);
        } else {
          // Retry to create item once
          createItem(list.user, list.id, itemName, quantity, note).then(
            (item) => {
              if (item) {
                updateListsWithNewItem(item);
              } else {
                setShowConnectionError(true);
                setTimeout(() => setShowConnectionError(false), 5000);
              }
            }
          );
        }
      });
    }
  };

  const handleToggleChecked = (checkedItem: IItem) => {
    const updatedItem = toggleCheckedItem(checkedItem);

    if (updatedItem.checked) {
      if (recentDeletedItems) {
        recentDeletedItems.forEach((item) => {
          deleteItem(list.user, list.id, item.id);
        });
        setRecentDeletedItems(undefined);
      }
      setRecentCheckedItem(updatedItem);
    }
    updateItem(list.user, list.id, updatedItem);
  };

  const handleDeleteCheckedItems = () => {
    if (recentDeletedItems) {
      recentDeletedItems?.forEach((item) => {
        deleteItem(list.user, list.id, item.id);
      });
    }

    if (itemList) {
      deleteCheckedItems();
      setRecentDeletedItems(itemList.items.filter((item) => item.checked));
    }
  };

  const handleDeleteItemFromItemList = (deletedItem: IItem) => {
    if (itemList) {
      deleteItemFromItemList(deletedItem);
      // Set items as recently deleted
      setRecentCheckedItem(undefined);
      recentDeletedItems?.forEach((item) => {
        deleteItem(list.user, list.id, item.id);
      });
      setRecentDeletedItems([deletedItem]);
    }
  };

  const regretCheckedItem = () => {
    if (recentCheckedItem) {
      handleToggleChecked(recentCheckedItem);
      setRecentCheckedItem(undefined);
    }
  };

  const regretDeletedItems = () => {
    // Add "deleted items" back to item and category list
    if (recentDeletedItems) {
      addItems(recentDeletedItems);
    }
    setRecentDeletedItems(undefined);
  };

  if (listStatus === Status.Loading) {
    return <LoadingPage />;
  }

  if (listStatus === Status.Error) {
    return <ErrorPage />;
  }

  return (
    <>
      <div className="shoppinglist-container" id="shoppinglist-container">
        <Search
          productCategoryData={productCategoryData}
          addItem={handleAddItem}
          hideSearch={() => setShowSearch(false)}
          showSearch={() => setShowSearch(true)}
          inSearchMode={inSearchMode}
        />
        <AnimatePresence>
          {showConnectionError && (
            <WarningMessage message="En feil oppstod ved lagring av varen. Vennligst prøv på nytt." />
          )}
        </AnimatePresence>
        <div
          className={`shoppinglist-content ${
            showSearch ? "shoppinglist-noscroll" : "shoppinglist-scroll"
          }`}
          id="shoppinglist-content"
        >
          <>
            <section className="shoppinglist-items-content">
              {showCategories && (
                <CategoryList
                  itemList={itemList}
                  coopCategories={coopCategories}
                  checkedCategoryName={checkedCategoryName}
                  toggleChecked={handleToggleChecked}
                  deleteCheckedItems={handleDeleteCheckedItems}
                  deleteItem={handleDeleteItemFromItemList}
                  updateItemCategory={updateItemCategory}
                />
              )}

              {!showCategories && (
                <UncategorizedList
                  itemList={itemList}
                  toggleChecked={handleToggleChecked}
                  deleteCheckedItems={handleDeleteCheckedItems}
                  deleteItem={handleDeleteItemFromItemList}
                  reorderItemList={reorderItemList}
                  updateItemCategory={updateItemCategory}
                />
              )}
            </section>
            {location.pathname.toLowerCase() !== "/b" && (
              <RecommendedList itemList={itemList} addItem={handleAddItem} />
            )}
          </>
          {showSearch && <div className="fade-overlay" />}
        </div>
      </div>
      <AnimatePresence>
        {recentCheckedItem && (
          <InfoPopup
            key="regret-checked-item"
            message={`\u00ab${recentCheckedItem.name}\u00bb er avkrysset`}
            buttonText="ANGRE"
            handleClick={regretCheckedItem}
          />
        )}
        {recentDeletedItems && (
          <InfoPopup
            key={`regret-delete-item`}
            message={`${
              recentDeletedItems.length === 1
                ? `\u00ab${recentDeletedItems[0].name}\u00bb`
                : `${recentDeletedItems.length} varer`
            } er slettet`}
            buttonText="ANGRE"
            handleClick={regretDeletedItems}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ShoppingList;
