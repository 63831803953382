import obs_alnabru from "icons/storeMaps/obs_alnabru.svg";
import obs_arendal from "icons/storeMaps/obs_arendal.svg";
import obs_bodø from "icons/storeMaps/obs_bodø.svg";
import obs_bryne from "icons/storeMaps/obs_bryne.svg";
import obs_buskerud from "icons/storeMaps/obs_buskerud.svg";
import obs_citysyd from "icons/storeMaps/obs_citysyd.svg";
import obs_elverum from "icons/storeMaps/obs_elverum.svg";
import obs_harstad from "icons/storeMaps/obs_harstad.svg";
import obs_haugenstua from "icons/storeMaps/obs_haugenstua.svg";
import obs_haugesund from "icons/storeMaps/obs_haugesund.svg";
import obs_horisont from "icons/storeMaps/obs_horisont.svg";
import obs_jessheim from "icons/storeMaps/obs_jessheim.svg";
import obs_kvadrat from "icons/storeMaps/obs_kvadrat.svg";
import obs_lade from "icons/storeMaps/obs_lade.svg";
import obs_lagunen from "icons/storeMaps/obs_lagunen.svg";
import obs_lesund from "icons/storeMaps/obs_lesund.svg";
import obs_lillestrøm from "icons/storeMaps/obs_lillestrøm.svg";
import obs_lyngdal from "icons/storeMaps/obs_lyngdal.svg";
import obs_mariero from "icons/storeMaps/obs_mariero.svg";
import obs_moirana from "icons/storeMaps/obs_moirana.svg";
import obs_olrud from "icons/storeMaps/obs_olrud.svg";
import obs_rudshøgda from "icons/storeMaps/obs_rudshøgda.svg";
import obs_rygge from "icons/storeMaps/obs_rygge.svg";
import obs_sandefjord from "icons/storeMaps/obs_sandefjord.svg";
import obs_sartor from "icons/storeMaps/obs_sartor.svg";
import obs_slitu from "icons/storeMaps/obs_slitu.svg";
import obs_sørlandssenteret from "icons/storeMaps/obs_sørlandssenteret.svg";
import obs_steinkjer from "icons/storeMaps/obs_steinkjer.svg";
import obs_troms from "icons/storeMaps/obs_troms.svg";
import obs_vestkanten from "icons/storeMaps/obs_vestkanten.svg";
import obs_vinterbro from "icons/storeMaps/obs_vinterbro.svg";

import coop_obs from "icons/coopLogos/obs_white_logo.svg";
import coop_extra from "icons/coopLogos/extra_yellow_logo.svg";
import coop_prix from "icons/coopLogos/coop_prix_logo.svg";
import coop_marked from "icons/coopLogos/coop_marked_logo.svg";
import coop_mega from "icons/coopLogos/coop_mega_logo.svg";
import matkroken from "icons/coopLogos/matkroken_white_logo.svg";
import obs_bygg from "icons/coopLogos/obs_bygg_white_logo.svg";

export const localStorageKeys = {
  STORE_OBS: "map-active-store-obs",
  STORETYPE: "map-active-storetype",
};

export const urlParams = {
  STORE: "butikk",
  STORETYPE: "butikkType",
};

export const storeType = new Map<string, string>([
  ["Obs", coop_obs],
  ["Extra", coop_extra],
  ["Prix", coop_prix],
  ["Marked", coop_marked],
  ["Mega", coop_mega],
  ["Matkroken", matkroken],
  ["Bygg", obs_bygg],
]);

export const storeTypeNames = Array.from(storeType.keys());

export const stores = new Map<string, string>([
  ["Obs Alnabru", obs_alnabru],
  ["Obs Arendal", obs_arendal],
  ["Obs Bodø", obs_bodø],
  ["Obs Bryne", obs_bryne],
  ["Obs Buskerud", obs_buskerud],
  ["Obs Citysyd", obs_citysyd],
  ["Obs Elverum", obs_elverum],
  ["Obs Harstad", obs_harstad],
  ["Obs Haugenstua", obs_haugenstua],
  ["Obs Haugesund", obs_haugesund],
  ["Obs Horisont", obs_horisont],
  ["Obs Jessheim", obs_jessheim],
  ["Obs Kvadrat", obs_kvadrat],
  ["Obs Lade", obs_lade],
  ["Obs Lagunen", obs_lagunen],
  ["Obs Lesund", obs_lesund],
  ["Obs Lillestrøm", obs_lillestrøm],
  ["Obs Lyngdal", obs_lyngdal],
  ["Obs Mariero", obs_mariero],
  ["Obs Mo i Rana", obs_moirana],
  ["Obs Olrud", obs_olrud],
  ["Obs Rudshøgda", obs_rudshøgda],
  ["Obs Rygge", obs_rygge],
  ["Obs Sandefjord", obs_sandefjord],
  ["Obs Sartor", obs_sartor],
  ["Obs Slitu", obs_slitu],
  ["Obs Sørlandssenteret", obs_sørlandssenteret],
  ["Obs Steinkjer", obs_steinkjer],
  ["Obs Troms", obs_troms],
  ["Obs Vestkanten", obs_vestkanten],
  ["Obs Vinterbro", obs_vinterbro],
]);

export const storeNames = Array.from(stores.keys());

// Get the last active store in each storeType from localStorage
export const initializeStore = (storeType: string): string | undefined => {
  switch (storeType) {
    case "Obs":
      const store = window.localStorage.getItem(localStorageKeys.STORE_OBS);
      if (store) {
        return JSON.parse(store);
      }
      return storeNames[0];
    default:
      return undefined;
  }
};

export const initializeStoreType = () => {
  const storeType = window.localStorage.getItem(localStorageKeys.STORETYPE);
  if (storeType) {
    return JSON.parse(storeType);
  } else {
    return storeTypeNames[0];
  }
};

// Save last seen store in each storeType in localstorage
export const writeStoreToLocalStorage = (storeType: string, store: string) => {
  switch (storeType) {
    case "Obs":
      localStorage.setItem(localStorageKeys.STORE_OBS, JSON.stringify(store));
      break;
    default:
  }
};

export const writeStoreTypeToLocalStorage = (storeType: string) => {
  localStorage.setItem(localStorageKeys.STORETYPE, JSON.stringify(storeType));
};
