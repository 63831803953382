import PopupWindow from "components/Shared/PopupWindow/PopupWindow";
import React, {
  useState,
  ChangeEvent,
  useContext,
  useRef,
  useEffect,
} from "react";
import { IItem } from "types/types";
import "./EditItemPopup.css";
import "components/Shared/PopupWindow/PopupWindow.css";
import { updateItem } from "client/shoppingListClient";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import { productContext } from "components/App/ContextProviders/ProductContext";
import userContext from "contexts/userContext";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/Shared/Buttons/Buttons";

interface IEditItemInfo {
  item: IItem;
  deleteItem: (deleteItem: IItem) => void;
  closeEditItemPopup: () => void;
  updateItemCategory: (item: IItem, newCategory: string) => void;
}

const EditItemPopup = ({
  item,
  deleteItem,
  closeEditItemPopup,
  updateItemCategory,
}: IEditItemInfo) => {
  const [highlightNumber, setHighlightNumber] = useState<number>(item.quantity);
  const [newItemName, setNewItemName] = useState<string>(item.name);
  const [note, setNote] = useState<string>(item.note ? item.note : "");
  const [category, setCategory] = useState<string>(item.category);
  const { productCategoryData } = useContext(productContext);
  const { activeList }: UserContextValues = useContext(userContext);
  const sortedCategoryList: string[] = productCategoryData?.categories.sort(
    (a: string, b: string) => a.localeCompare(b)
  );

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    setNewItemName(input);
  };
  const selectedQuantityRef = useRef<HTMLButtonElement>(null);

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let input = e.target.value;
    setNote(input);
  };

  const handleSelectorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
  };

  useEffect(() => {
    if (selectedQuantityRef.current) {
      selectedQuantityRef.current.scrollIntoView({
        inline: "center",
        behavior: "smooth",
      });
    }
  }, [highlightNumber]);

  const saveItem = () => {
    item.quantity = highlightNumber;
    item.name = newItemName;
    item.note = note;
    if (item.category !== category) {
      updateItemCategory(item, category);
    }
    updateItem(activeList.user, activeList.id, item);
  };

  return (
    <PopupWindow closePopup={closeEditItemPopup}>
      <input
        className="edit-item-input"
        aria-label="endre navn på varen"
        type="text"
        name="item"
        value={newItemName}
        onChange={handleNameChange}
        autoComplete="off"
        autoCorrect="off"
        autoFocus={true}
      />

      <select
        className="edit-dropdown"
        name="category"
        defaultValue={item.category}
        onChange={handleSelectorChange}
        aria-label="Endre kategori"
      >
        {sortedCategoryList?.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
      <div className="edit-quantity-slider">
        {Array.from(Array(20), (_, i) => (
          <button
            aria-label={`endre antall ${i + 1}`}
            key={i}
            ref={i + 1 === highlightNumber ? selectedQuantityRef : undefined}
            className={`edit-quantity-button ${
              i + 1 === highlightNumber ? "edit-quantity-button-selected" : ""
            } ${i === 0 ? "edit-quantity-button-first" : ""} ${
              i === 19 ? "edit-quantity-button-last" : ""
            }`}
            onClick={() => {
              setHighlightNumber(i + 1);
            }}
          >
            <span className="edit-quantity-button-text">{i + 1}</span>
          </button>
        ))}
        <span className="edit-empty-span"></span>
      </div>
      <textarea
        className="edit-note-input"
        name="notat"
        placeholder="Legg til notat"
        value={note}
        onChange={handleNoteChange}
        autoComplete="off"
        autoCorrect="off"
        autoFocus={false}
      />
      <div className="popup-buttons-container">
        <SecondaryButton onClick={() => closeEditItemPopup()}>
          Avbryt
        </SecondaryButton>
        <PrimaryButton
          id="confirm-edit-item"
          onClick={() => {
            saveItem();
            closeEditItemPopup();
          }}
        >
          Lagre
        </PrimaryButton>
      </div>
      <button className="edit-delete-button" onClick={() => deleteItem(item)}>
        Slett vare
      </button>
    </PopupWindow>
  );
};

export default EditItemPopup;
