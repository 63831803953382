import {
  ICategory,
  IDragPosition,
  IExtendedList,
  IItem,
  IItemList,
} from "types/types";
import _ from "lodash";
import { updateItem } from "client/shoppingListClient";

export const getNewCategory = (
  categoryList: ICategory[],
  source: IDragPosition,
  destination: IDragPosition
) => {
  const selectedItem = getSelectedItem(categoryList, source);
  categoryList.forEach((category) => {
    if (category.name === destination.droppableId.split("-")[1]) {
      if (selectedItem) {
        if (destination.droppableId.split("-")[1] === "Avkrysset") {
          return "Avkrysset";
        } else {
          return category.name;
        }
      }
    }
  });
};

export const getSelectedItem = (
  categoryList: ICategory[],
  source: IDragPosition
) => {
  const selectedProduct = categoryList
    .map((category) => {
      let product = undefined;
      if (category.name === source.droppableId.split("-")[1]) {
        product = category.items.splice(source.index, 1)[0];
      }
      return product;
    })
    .filter((prod) => prod !== undefined)[0];
  return selectedProduct;
};

export const move = (to: number, from: number, list: IItem[]) => {
  list.splice(from, 0, list.splice(to, 1)[0]);
  return list;
};

export const categorizeList = (
  itemList: IItemList,
  checkedCategoryName: string,
  coopCategories: string[]
) => {
  var newCategoryList: ICategory[] = [];

  var groupCategories = _.mapValues(_.groupBy(itemList.items, "category"));

  _.forOwn(groupCategories, function (value, key) {
    newCategoryList.push({ name: key, items: value });
  });

  const checkedCategory: ICategory = { name: checkedCategoryName, items: [] };
  newCategoryList = newCategoryList
    .map((category) => {
      category.items = category.items.filter((item) => {
        if (item.checked) {
          checkedCategory.items = [item, ...checkedCategory.items];
          return false;
        }
        return true;
      });
      return category;
    })
    .filter((category) => category.items.length !== 0);

  newCategoryList.sort(function (a, b) {
    return coopCategories.indexOf(a.name) - coopCategories.indexOf(b.name);
  });

  if (checkedCategory.items.length === 0) {
    return newCategoryList;
  }
  return [...newCategoryList, checkedCategory];
};

export const reorderCategoryList = (
  source: IDragPosition,
  destination: IDragPosition,
  categoryList: ICategory[],
  activeList: IExtendedList
) => {
  if (!destination) {
    return categoryList;
  }
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return categoryList;
  }
  const newCategory = destination.droppableId.split("-")[1];
  const prevCategory = source.droppableId.split("-")[1];
  const updatedCategoryList = categoryList;
  // If an item is moved within a category
  if (destination.droppableId === source.droppableId) {
    updatedCategoryList.forEach((category) => {
      if (category.name === newCategory) {
        move(source.index, destination.index, category.items);
      }
    });
  } else {
    // if an item is moved to a different category
    const selectedItem = getSelectedItem(updatedCategoryList, source);
    updatedCategoryList.forEach((category) => {
      if (category.name === destination.droppableId.split("-")[1]) {
        if (selectedItem) {
          // If the item is moved from "Avkrysset"
          if (prevCategory === "Avkrysset") {
            selectedItem.checked = false;
            selectedItem.category = category.name;
            category.items.splice(destination.index, 0, selectedItem);
            // If the item is moved to "Avkrysset"
          } else if (newCategory === "Avkrysset") {
            selectedItem.checked = true;
            category.items.splice(destination.index, 0, selectedItem);
          } else {
            selectedItem.category = category.name;
            category.items.splice(destination.index, 0, selectedItem);
          }
          // Update the item in backend
          updateItem(activeList.user, activeList.id, selectedItem);
        }
      }
    });
  }
  return updatedCategoryList.filter((category) => category.items.length !== 0);
};
