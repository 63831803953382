import { v4, validate } from "uuid";

const createNewUser = (): string => {
  const newUser = v4();
  window.localStorage.setItem("user", newUser);
  return newUser;
};

export const getUser = () => {
  const storedUser = window.localStorage.getItem("user");
  return storedUser && validate(storedUser) ? storedUser : createNewUser();
};
