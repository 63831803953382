import { createShoppingList } from "client/shoppingListClient";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import PopupWindow from "components/Shared/PopupWindow/PopupWindow";
import WarningMessage from "components/Shared/WarningMessage/WarningMessage";
import userContext from "contexts/userContext";
import { AnimatePresence } from "framer-motion";
import React, {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useContext,
  createRef,
} from "react";
import { IExtendedList } from "types/types";
import "../PopupWindows.css";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/Shared/Buttons/Buttons";

interface ICreateShoppingList {
  switchActiveList: (list: IExtendedList) => void;
  closeCreateShoppingList: () => void;
}

const CreateShoppingList = ({
  closeCreateShoppingList,
  switchActiveList,
}: ICreateShoppingList) => {
  const [shoppingListName, setShoppingListName] = useState<string>("");
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
  const [showEmptyNameWarning, setShowEmptyNameWarning] =
    useState<boolean>(false);
  const { userID, addPersonalList }: UserContextValues =
    useContext(userContext);
  const inputRef = createRef<HTMLInputElement>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let listName = e.target.value;
    setShoppingListName(listName);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      createNewList(shoppingListName.trim());
    }
  };

  const createNewList = (listName: string) => {
    if (listName === "") {
      setShowEmptyNameWarning(true);
      inputRef.current?.focus();
    } else {
      createShoppingList(userID, listName).then((newList) => {
        if (newList) {
          addPersonalList(newList);
          switchActiveList({
            user: userID,
            id: newList.id,
            name: newList.name,
            shareId: newList.shareId,
          });
          closeCreateShoppingList();
        } else {
          // Retry to create shoppinglist if not successful
          createShoppingList(userID, listName).then((newList) => {
            if (newList) {
              addPersonalList(newList);
              switchActiveList({
                user: userID,
                id: newList.id,
                name: newList.name,
                shareId: newList.shareId,
              });
              closeCreateShoppingList();
            } else {
              setShowWarningMessage(true);
              setTimeout(() => setShowWarningMessage(false), 8000);
            }
          });
        }
      });
    }
  };

  return (
    <>
      <AnimatePresence>
        {showWarningMessage && (
          <WarningMessage
            message={
              "En feil oppstod under opprettelsen av handlelisten. Vennligst prøv på nytt."
            }
          />
        )}
      </AnimatePresence>
      <PopupWindow closePopup={closeCreateShoppingList}>
        <h3 className="popup-header">Opprett ny handleliste</h3>

        <input
          className={`popup-input${showEmptyNameWarning ? "-warning" : ""}`}
          type="text"
          name="search"
          id="test"
          ref={inputRef}
          placeholder="F.eks. Ukeshandel"
          value={shoppingListName}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoComplete="off"
          autoCorrect="off"
          autoFocus={true}
        />
        <div
          className={`empty-name-warning ${showEmptyNameWarning ? "" : "hide"}`}
        >
          Obs! Listen mangler navn.
        </div>
        <div className="popup-buttons-container popup-buttons-container-less-space-above">
          <SecondaryButton onClick={closeCreateShoppingList}>
            Avbryt
          </SecondaryButton>
          <PrimaryButton
            id="confirm-create-new-list"
            onClick={() => createNewList(shoppingListName.trim())}
          >
            Opprett
          </PrimaryButton>
        </div>
      </PopupWindow>
    </>
  );
};

export default CreateShoppingList;
