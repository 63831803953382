import React, { useState, useEffect } from "react";
import { IItemList } from "types/types";
import recommendedProductList from "data/recommendedProductList.json";
import "./RecommendedList.css";
import { motion } from "framer-motion";

interface IRecommendedList {
  itemList: IItemList;
  addItem: (productName: string) => void;
}

const RecommendedList = ({ itemList, addItem }: IRecommendedList) => {
  const [recommendedProducts, setRecommendedProducts] = useState<string[]>([]);
  useEffect(() => {
    if (itemList.items) {
      const itemListContent = itemList.items.flatMap((item) =>
        item.name.toLowerCase()
      );
      const recommendedProductsTemp = recommendedProductList.filter(
        (productName) => !itemListContent.includes(productName.toLowerCase())
      );
      setRecommendedProducts(recommendedProductsTemp);
    }
  }, [itemList]);

  const spring = {
    type: "spring",
    damping: 25,
    stiffness: 120,
  };

  if (recommendedProducts.length === 0) {
    return <></>;
  }

  return (
    <motion.div
      className="recommended-container"
      layout="position"
      transition={spring}
    >
      <section className="recommended-content">
        <h3 className="recommended-title" id="recommended-title">
          VARER DU KANSKJE TRENGER
        </h3>
        <ul className="recommended-list" aria-labelledby="recommended-title">
          {recommendedProducts.slice(0, 10).map((productName, i) => {
            return (
              <button
                className={`recommended-item`}
                key={i}
                onClick={() => addItem(productName)}
                tabIndex={0}
              >
                <span className="recommended-name">{productName}</span>
                <span className="recommended-add-item-text">Legg til</span>
              </button>
            );
          })}
        </ul>
      </section>
    </motion.div>
  );
};

export default RecommendedList;
