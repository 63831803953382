import React from "react";
import "./MapFeedback.css";

const MapFeedback = () => {
  const isObsMap = window.location.search.includes("Obs");
  const obsMapFeedbackUrl =
    "https://forms.office.com/Pages/ResponsePage.aspx?id=JMASrSDjGUuqC7DDbBNucGjZ2uRF5-JNqnukF_01GzdURTMwRENJMEZIRFhRWjVHUllKMVhGVUxaUi4u";
  const unimplemetedMapFeedbackUrl =
    "https://forms.office.com/Pages/ResponsePage.aspx?id=JMASrSDjGUuqC7DDbBNucGjZ2uRF5-JNqnukF_01GzdUNllUWUhWRzlKOUsyRjJBM0VMNTBQQVNHTS4u";

  return (
    <div className="map-feedback-container">
      <h3 className="map-feedback-header">Vi holder på å lage kart</h3>
      <span className="map-feedback-sub-header">
        Er dette noe du ønsker deg?
      </span>
      <span className="map-feedback-text">Gi oss din tilbakemelding</span>
      <a
        className="map-feedback-link"
        href={isObsMap ? obsMapFeedbackUrl : unimplemetedMapFeedbackUrl}
      >
        Gi tilbakemelding
      </a>
    </div>
  );
};
export default MapFeedback;
