import { getShoppingList, updateItem } from "client/shoppingListClient";
import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import userContext from "contexts/userContext";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  IDragPosition,
  IExtendedList,
  IItem,
  IItemList,
  Status,
} from "types/types";

const useItemList = () => {
  const [lastActiveList, setLastActiveList] = useState<
    IExtendedList | undefined
  >(undefined);
  const [itemList, setItemList] = useState<IItemList>({} as IItemList);
  const [listStatus, setListStatus] = useState<Status>(Status.Loading);
  const { activeList, lists }: UserContextValues = useContext(userContext);

  const getItemListOnce = useCallback(() => {
    setListStatus(Status.Loading);
    // Get content of shoppinglist with listID one time
    getShoppingList(activeList.user, activeList.id)
      .then((shoppingList) => {
        if (shoppingList) {
          const loadedItemList: IItemList = {
            name: activeList.name,
            items: shoppingList.items,
          };
          setItemList(sortItemList(loadedItemList));
          setListStatus(Status.Ok);
        } else {
          // Something went wrong when getting shopping list from backend
          setListStatus(Status.Error);
        }
      })
      .catch((e) => setListStatus(Status.Error));
  }, [activeList]);

  useEffect(() => {
    if (!lastActiveList) {
      const listInfo = lists?.find((list) => list.id === activeList.id);
      if (listInfo) {
        getItemListOnce();
      }
      setLastActiveList(activeList);
    }

    if (lastActiveList && activeList.id !== lastActiveList.id) {
      const listInfo = lists?.find((list) => list.id === activeList.id);
      if (listInfo) {
        getItemListOnce();
      }

      setLastActiveList(activeList);
    }
  }, [getItemListOnce, activeList, lastActiveList, lists]);

  const sortItemList = (itemList: IItemList): IItemList => {
    const sortedList = itemList;
    sortedList.items = sortedList.items
      .sort((x, y) => Number(x.createTime) - Number(y.createTime))
      .sort((x, y) => Number(x.checked) - Number(y.checked));
    return sortedList;
  };

  const move = (to: number, from: number, list: IItem[]) => {
    list.splice(from, 0, list.splice(to, 1)[0]);
    return list;
  };

  const addItem = useCallback(
    (newItem: IItem) => {
      const updatedItems = [newItem, ...itemList.items];
      setItemList(sortItemList({ ...itemList, items: updatedItems }));
    },
    [itemList]
  );

  const addItems = useCallback(
    (newItems: IItem[]) => {
      const updatedItems = [...newItems, ...itemList.items];
      setItemList(sortItemList({ ...itemList, items: updatedItems }));
    },
    [itemList]
  );

  const updateItemCategory = useCallback(
    (item: IItem, newCategory: string) => {
      const updatedItem = item;
      updatedItem.category = newCategory;

      const updatedItems = itemList?.items.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
      setItemList(sortItemList({ ...itemList, items: updatedItems }));
      updateItem(activeList.user, activeList.id, updatedItem);
    },
    [itemList, activeList]
  );

  const toggleCheckedItem = useCallback(
    (checkedItem: IItem): IItem => {
      const updatedItem = checkedItem;
      updatedItem.checked = !checkedItem.checked;
      const updatedItems = itemList?.items.map((item) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
      const updatedItemList: IItemList = {
        ...itemList,
        items: updatedItems,
      };
      setItemList(sortItemList(updatedItemList));
      updateItem(activeList.user, activeList.id, updatedItem);

      return updatedItem;
    },
    [itemList, activeList]
  );

  const deleteItemFromItemList = useCallback(
    (deletedItem: IItem) => {
      if (itemList) {
        const updatedItems = itemList.items.filter(
          (item) => item.id !== deletedItem.id
        );
        setItemList(sortItemList({ ...itemList, items: updatedItems }));
      }
    },
    [itemList]
  );

  const deleteCheckedItems = useCallback(() => {
    const updatedItems = itemList.items.filter((item) => !item.checked);
    setItemList(sortItemList({ ...itemList, items: updatedItems }));
  }, [itemList]);

  const reorderItemList = useCallback(
    (destination: IDragPosition, source: IDragPosition) => {
      // dropped outside the activeList
      if (!destination) {
        return;
      }
      if (destination.index === source.index) {
        return;
      }
      const updatedItemList = itemList;
      const selectedItem = updatedItemList.items[source.index];
      const replacedItem = updatedItemList.items[destination.index];
      selectedItem.checked = replacedItem.checked;
      move(source.index, destination.index, updatedItemList.items);
      updateItem(activeList.user, activeList.id, selectedItem);
      setItemList(updatedItemList);
    },
    [itemList, activeList]
  );

  return {
    listStatus,
    itemList,
    setItemList,
    addItem,
    addItems,
    updateItemCategory,
    toggleCheckedItem,
    deleteItemFromItemList,
    deleteCheckedItems,
    reorderItemList,
  };
};

export default useItemList;
