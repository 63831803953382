import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addShoppingListEditor } from "../../../client/shoppingListClient";
import { UserContextValues } from "../ContextProviders/UserContextProvider";
import userContext from "../../../contexts/userContext";
import LoadingPage from "../../LoadingPage/LoadingPage";

type ShareParams = {
  shareID: string;
};

const InviteUserToList = () => {
  const navigate = useNavigate();
  const { addSharedListAndSetAsActive, userID }: UserContextValues =
    useContext(userContext);
  const { shareID } = useParams<ShareParams>();

  useEffect(() => {
    const addListToUser = async () => {
      if (shareID) {
        let ok = await addShoppingListEditor(userID, shareID);
        if (ok) {
          addSharedListAndSetAsActive(shareID);
          navigate("/");
        } else {
          navigate("/404");
        }
      } else {
        navigate("/404");
      }
    };

    addListToUser().catch(console.error);
  }, [shareID, userID, navigate, addSharedListAndSetAsActive]);

  return <LoadingPage />;
};

export default InviteUserToList;
