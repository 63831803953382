import React from "react";
import {
  CloseWindowButton,
  PrimaryButton,
} from "components/Shared/Buttons/Buttons";
import "./LoginHint.css";
import { LiveWindow } from "components/Shared/LiveWindow/LiveWindow";

interface ILoggedInHint {
  closeWindow: () => void;
}

export const LoggedInFeedback = ({ closeWindow }: ILoggedInHint) => (
  <LiveWindow>
    <div className="loginhint-close-button">
      <CloseWindowButton onClick={closeWindow} />
    </div>
    <h3 className="loginhint-title">Du er logget inn! 🎉</h3>
    <p className="loginhint-text">
      Du kan også logge inn på andre enheter for å finne og redigere
      handlelistene dine
    </p>
    <PrimaryButton className="loginhint-button" onClick={closeWindow}>
      Lukk
    </PrimaryButton>
  </LiveWindow>
);
