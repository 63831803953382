import React, { useState } from "react";
import { CollapseProps, UnmountClosed } from "react-collapse";
import "./Collapsible.css";
import chevronIcon from "icons/union.svg";

interface CollapsibleProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  open: boolean;
  layoutId: string;
  toggleOpen: () => void;
  collapseProps?: Omit<CollapseProps, "children" | "isOpened">;
  collapseTitle: React.ReactNode;
}

const chevronCls = (open: boolean, animate: boolean): string => {
  let animationCls;
  if (animate) {
    animationCls = open
      ? "collapsible__chevron--open-animate"
      : "collapsible__chevron--closed-animate";
  } else {
    animationCls = open
      ? "collapsible__chevron--open"
      : "collapsible__chevron--closed";
  }
  return `collapsible__chevron ${animationCls}`;
};

export const Collapsible = (props: CollapsibleProps) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [id] = useState(props.id || Math.random().toString().substr(2, 5));

  const onClick = (event: React.SyntheticEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setShowAnimation(true);
    props.toggleOpen();
  };

  return (
    <>
      <button
        id={id}
        className="collapsible__button"
        onClick={onClick}
        aria-expanded={props.open}
        aria-controls={`${id}-content`}
        type="button"
      >
        <div className="collapsible__title">
          <span>{props.collapseTitle}</span>
          <img
            src={chevronIcon}
            alt="open"
            className={`${chevronCls(!!props.open, showAnimation)}`}
          />
        </div>
      </button>
      <div
        role="region"
        id={`${id}-content`}
        aria-labelledby={id}
        className="collapsible__content"
      >
        <UnmountClosed isOpened={!!props.open} {...props.collapseProps}>
          {props.children}
        </UnmountClosed>
      </div>
    </>
  );
};
