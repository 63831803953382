import { UserContextValues } from "components/App/ContextProviders/UserContextProvider";
import userContext from "contexts/userContext";
import React, { useContext } from "react";
import addIcon from "icons/add_circle.svg";
import moreIcon from "icons/more_horizontal_darkblue.svg";
import { IList, UserRole } from "types/types";
import "./MainMenu.css";
import "../Menu.css";
import {
  PrimaryButton,
  SecondaryButton,
} from "components/Shared/Buttons/Buttons";
import { useAuth0 } from "@auth0/auth0-react";

interface IMainPageProps {
  closeMenu: () => void;
  openCreateListPopup: () => void;
  setListChosen: (list: IList) => void;
  setSharedListChosen: (sharedList: IList) => void;
}

const MainMenu = ({
  closeMenu,
  openCreateListPopup,
  setListChosen,
  setSharedListChosen,
}: IMainPageProps) => {
  const { userID, activeList, lists, switchActiveList }: UserContextValues =
    useContext(userContext);
  const { isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
  return (
    <div className="main-menu-content">
      <div className="menu-element">
        <h3 className="menu-header" id="menu-header">
          Mine handlelister
        </h3>
      </div>
      <hr className="horizontal-line" />
      <ul className="menu-element-list" aria-labelledby="menu-header">
        {lists &&
          lists.map((list) => {
            return (
              <li className="menu-element-wrapper" key={list.id}>
                <div className="menu-element">
                  <button
                    className="menu-element-button"
                    onClick={() => {
                      switchActiveList({
                        user: userID,
                        id: list.id,
                        name: list.name,
                        shareId: list.shareId,
                      });
                      closeMenu();
                    }}
                  >
                    <div className="menu-element-text">
                      <h4
                        className={`menu-element-title ${
                          list.id === activeList.id
                            ? "menu-element-title-active"
                            : ""
                        }`}
                      >
                        {list.name}
                      </h4>
                      <span className="menu-element-subtitle">
                        {list.role === UserRole.Owner ? "Eier" : "Delt med meg"}
                      </span>
                    </div>
                  </button>
                  <button
                    className="more-options-button"
                    onClick={() => {
                      if (list.role === UserRole.Owner) {
                        setListChosen(list);
                        return;
                      }
                      setSharedListChosen(list);
                      return;
                    }}
                  >
                    <img src={moreIcon} alt="Flere valg for listen" />
                  </button>
                </div>
                <hr className="horizontal-line" />
              </li>
            );
          })}
      </ul>
      <div className="menu-element">
        <button
          className="menu-new-list-button"
          onClick={() => {
            openCreateListPopup();
          }}
        >
          <img className="menu-new-list-icon" src={addIcon} alt="" />
          <h4 className="menu-element-title">Opprett ny liste</h4>
        </button>
      </div>
      {window.location.pathname.includes("logintest") && (
        <>
          <hr className="horizontal-line" />
          {!isLoading && isAuthenticated ? (
            <div className="menu-element-sign-in-out">
              <SecondaryButton
                className="menu-button--login"
                onClick={() => logout()}
              >
                Logg ut
              </SecondaryButton>
            </div>
          ) : (
            <div className="menu-element-sign-in-out">
              <PrimaryButton
                className="menu-button--login"
                onClick={() => loginWithRedirect()}
              >
                Logg inn
              </PrimaryButton>
              {window.location.hostname.includes("staging") && (
                <PrimaryButton
                  className="menu-button--login"
                  onClick={() => loginWithRedirect({ enable2fa: "true" })}
                >
                  Logg inn med MFA
                </PrimaryButton>
              )}
              <span className="menu-element-subtitle">
                Få tilgang til listene hvor enn du er
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MainMenu;
