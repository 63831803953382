import React, { useState } from "react";
import { IItem } from "types/types";
import move from "icons/move.svg";
import edit from "icons/edit_pencil.svg";
import "./Item.css";
import { AnimatePresence } from "framer-motion";
import EditInfoPopup from "./EditItemPopup/EditItemPopup";

import { Draggable } from "react-beautiful-dnd";

interface IItemProps {
  item: IItem;
  toggleChecked: (item: IItem) => void;
  deleteItem: (deleteItem: IItem) => void;
  index: number;
  updateItemCategory: (item: IItem, newCategory: string) => void;
}

const Item = ({
  item,
  toggleChecked,
  deleteItem,
  index,
  updateItemCategory,
}: IItemProps) => {
  const [showEditItemPopup, setshowEditItemPopup] = useState<boolean>(false);

  const checkedItemStyle = (isChecked: boolean): string => {
    return `item-element ${isChecked ? "item-checked" : "item-unchecked"}`;
  };

  const checkboxStyle = (isDragging: boolean): string => {
    if (isDragging) {
      return "item-drag-icon";
    }
    return `checkbox`;
  };

  const handleEditClick = (event: any) => {
    setshowEditItemPopup(true);
  };

  return (
    <>
      <li className="item-container">
        <Draggable
          key={item.id}
          draggableId={String(item.id)}
          index={index}
          isDragDisabled={false}
        >
          {(provided, snapshot) => (
            <div
              className={`item-drag-container`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              tabIndex={-1}
              role="group"
            >
              <div
                className={`${checkedItemStyle(item.checked)} ${
                  snapshot.isDragging && !snapshot.isDropAnimating
                    ? "item-drag"
                    : ""
                }
                `}
                key={item.id}
              >
                <div
                  className={`item-text-container ${
                    snapshot.isDragging && !snapshot.isDropAnimating
                      ? ""
                      : "item-text-nodrag"
                  } ${item.checked ? "item-text-container-checked" : ""}`}
                  tabIndex={0}
                  onClick={() => toggleChecked(item)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      toggleChecked(item);
                    }
                  }}
                  role="button"
                >
                  <div className="item-description-container">
                    <div className="item-name" id={`item-${item.id}`}>
                      {item.name}
                    </div>
                    <div
                      className={`item-note ${
                        item.checked ? "item-note-checked" : ""
                      }`}
                    >
                      {item.note}
                    </div>
                  </div>
                  {item.quantity > 1 && (
                    <span className="item-quantity">{item.quantity}</span>
                  )}
                </div>
                <button
                  className={`item-edit-button ${item.checked ? "hide" : ""}`}
                  onClick={handleEditClick}
                  aria-labelledby={`item-${item.id}`}
                >
                  <div
                    className={checkboxStyle(
                      snapshot.isDragging && !snapshot.isDropAnimating
                    )}
                  >
                    {!(snapshot.isDragging && !snapshot.isDropAnimating) &&
                      !item.checked && (
                        <img
                          className="item-button-img"
                          src={`${edit}`}
                          alt="Rediger varen"
                        />
                      )}
                    {snapshot.isDragging && !snapshot.isDropAnimating && (
                      <img
                        className="item-button-img"
                        src={`${move}`}
                        alt="Varen er krysset ut"
                      />
                    )}
                  </div>
                </button>
              </div>
            </div>
          )}
        </Draggable>
      </li>
      <AnimatePresence>
        {showEditItemPopup && (
          <EditInfoPopup
            item={item}
            closeEditItemPopup={() => setshowEditItemPopup(false)}
            deleteItem={deleteItem}
            updateItemCategory={updateItemCategory}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Item;
